import React, { useEffect } from 'react';
import { useForm, useWatch } from 'antd/es/form/Form';
import { Button, Form, Input, Modal } from 'antd';
import { FormInstance } from 'antd/es/form/hooks/useForm';
import { UserRoleSelector } from '../../selectors/UsersSelector';
import { Spinner } from '../../../../../components/Spinner';
import { UserRole } from '../../../../../lib/types';

export interface UserModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onSubmit: (values: any, form: FormInstance) => Promise<void>;
  loading?: boolean;
  values?: any;
  partial?: boolean;
  title: string;
  okText: string;
  role?: UserRole;
}

export const UserModal: React.FC<UserModalProps> = ({
  open,
  setOpen,
  onSubmit,
  loading,
  values,
  partial,
  title,
  okText,
  role: selectedRole,
}) => {
  const [form] = useForm();
  const role = useWatch('role', form);

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    } else if (selectedRole) {
      form.setFieldValue('role', selectedRole);
    }
  }, [open]);

  useEffect(() => {
    if (values) {
      form.setFieldsValue(values);
    }
  }, [values]);

  return (
    <Modal
      open={open}
      title={title}
      onCancel={handleCancel}
      confirmLoading={loading}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={form.submit}>
          {okText}
        </Button>,
      ]}
    >
      <Spinner spinning={loading}>
        <Form
          name="basic"
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={(values) => onSubmit(values, form)}
          defaultValue={values}
        >
          <Form.Item
            label="Role"
            name="role"
            rules={[
              {
                required: true,
                message: 'Role is required',
              },
            ]}
          >
            <UserRoleSelector />
          </Form.Item>
          <Form.Item
            label="First name"
            name="firstName"
            rules={[
              {
                required: true,
                message: 'First name is required',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last name"
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Last name is required',
              },
            ]}
          >
            <Input />
          </Form.Item>
          {[UserRole.Admin, UserRole.Teacher].includes(role) && (
            <Form.Item
              label="E-mail"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Input an email',
                },
                {
                  type: 'email',
                  message: 'Input a correct email',
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          {[UserRole.Admin, UserRole.Teacher].includes(role) && (
            <Form.Item
              label="Password"
              name="password"
              rules={
                [
                  partial
                    ? null
                    : {
                        required: true,
                        message: 'Input a password',
                      },
                ].filter(Boolean) as any
              }
              required={!partial}
            >
              <Input.Password />
            </Form.Item>
          )}
        </Form>
      </Spinner>
    </Modal>
  );
};
