import React, { useEffect } from 'react';
import './MoveClassModal.css';
import { Button, Form, Modal } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { useForm } from 'antd/es/form/Form';
import { tr } from '../../../../../lib/translate';
import { DatePicker } from '../../../../../components/DatePicker';
import { TimePicker } from '../../../../../components/TimePicker';
import { client } from '../../../../../client';
import dayjs from 'dayjs';
import {
  GET_CLASSES_QUERY_NAME,
  UPDATE_CLASS_MUTATION,
} from '../../../graphql';
import { GET_CLASSES_REPORT_QUERY_NAME } from '../../../../reports/graphql';

export interface ClassModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  classId: number | null;
}

export const MoveClassModal: React.FC<ClassModalProps> = ({
  open,
  setOpen,
  classId,
}) => {
  const handleCancel = () => {
    setOpen(false);
  };
  const [addClass, { loading }] = useMutation(UPDATE_CLASS_MUTATION, {
    refetchQueries: [GET_CLASSES_QUERY_NAME, GET_CLASSES_REPORT_QUERY_NAME],
    awaitRefetchQueries: true,
  });
  const classItem = client.readFragment({
    id: `ClassModel:${classId}`,
    fragment: gql`
      fragment ClassModel on ClassModel {
        id
        date
        time
      }
    `,
  });

  const [form] = useForm();

  const onFinish = async (values: any) => {
    await addClass({
      variables: {
        id: classId,
        date: values.date.format('YYYY-MM-DD'),
        time: values.time.format('HH:mm'),
      },
      refetchQueries: ['GetClasses'],
      awaitRefetchQueries: true,
    });

    form.resetFields();

    setOpen(false);
  };

  useEffect(() => {
    if (classItem) {
      form.setFieldsValue({
        date: dayjs(classItem.date),
        time: dayjs(classItem.time, 'HH:mm'),
      });
    }
  }, [classItem?.id]);

  return (
    <Modal
      className={'class-modal'}
      open={open}
      title={tr('calendar.classes.move.title')}
      onCancel={handleCancel}
      confirmLoading={loading}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {tr('modal.buttons.cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={form.submit}
          loading={loading}
        >
          {tr('calendar.classes.move.actions.move.label')}
        </Button>,
      ]}
    >
      <Form
        name="basic"
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={(values) => onFinish(values)}
      >
        <Form.Item
          label={tr('classes.add-class.fields.date.label')}
          name="date"
          rules={[
            {
              required: true,
              message: tr('classes.add-class.fields.date.errors.required'),
            },
          ]}
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item
          label={tr('classes.add-class.fields.time.label')}
          name="time"
          rules={[
            {
              required: true,
              message: tr('classes.add-class.fields.time.errors.required'),
            },
          ]}
        >
          <TimePicker showSecond={false} minuteStep={30} format="HH:mm" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
