import React from 'react';
import { UserRole } from '../../../../../lib/types';
import { UsersSelector, UsersSelectorProps } from '../UserRoleSelector';
import { tr } from '../../../../../lib/translate';

export interface StudentsSelectorProps extends UsersSelectorProps {}

export const StudentsSelector: React.FC<StudentsSelectorProps> = (props) => {
  return (
    <UsersSelector
      roleIn={[UserRole.Student]}
      placeholder={tr('common.users-selector.students.placeholder')}
      showAddUserRole={UserRole.Student}
      {...props}
    />
  );
};
