import { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, InputRef } from 'antd';
import './EditableCell.css';
import { get, cloneDeep, set, merge } from 'lodash';
import { EditableContext } from '../EditableRow';

interface EditableCellProps {
  editable: boolean;
  children: React.ReactNode;
  record: any;
  handleSave: (record: any) => void;
  dataIndex: string;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editable,
  children,
  record,
  handleSave,
  dataIndex,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  useEffect(() => {
    const object = {};

    set(object, dataIndex, get(record, dataIndex));

    form.setFieldsValue(object);
  }, []);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  let childNode = children;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const save = async () => {
    const values = await form.validateFields();
    toggleEdit();
    const clone = cloneDeep(record);
    handleSave(merge({ ...clone }, values));
  };

  if (editable) {
    childNode = (
      <Form.Item name={dataIndex.split('.')} style={{ marginBottom: 0 }}>
        <Input
          className="editable-cell__input"
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          bordered={true}
          onClick={toggleEdit}
        />
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
