import React, { useState } from 'react';
import './CalendarPage.css';
import { Button, Calendar, List, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { useMutation, useQuery } from '@apollo/client';
import { addDurationToTime } from '../../lib/utils';
import { Spinner } from '../../components/Spinner';
import { tr } from '../../lib/translate';
import { AddClassModal } from '../../modules/classes/components/modals/AddClassModal';
import { MoveClassModal } from '../../modules/classes/components/modals/MoveClassModal';
import { ClassStatus } from '../../lib/types';
import { PageLayout } from '../../containers/PageLayout';
import {
  GET_CLASSES_QUERY,
  GET_CLASSES_QUERY_NAME,
  GET_REQUIRED_ATTENTION_CLASSES_COUNT_QUERY_NAME,
  REMOVE_CLASS_MUTATION,
} from '../../modules/classes/graphql';
import { ClassGroupTrialTag } from '../../modules/class-groups/components/tags/ClassGroupTrialTag';
import { GET_CLASS_GROUPS_QUERY_NAME } from '../../modules/class-groups/graphql';
import { useCurrentUser } from '../../hooks/useCurrentUser';

export const CalendarPage = () => {
  const [addModalOpen, setAddModalOpen] = React.useState(false);
  const [moveModalOpen, setMoveModalOpen] = React.useState(false);

  const [date, setDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [classId, setClassId] = useState<number | null>(null);
  const user = useCurrentUser();

  const { data, loading } = useQuery(GET_CLASSES_QUERY, {
    variables: {
      date: date,
      teacherId: user.id,
    },
    skip: !date,
  });

  const [removeClass, { loading: removeClassLoading }] = useMutation(
    REMOVE_CLASS_MUTATION,
    {
      refetchQueries: [
        GET_CLASSES_QUERY_NAME,
        GET_CLASS_GROUPS_QUERY_NAME,
        GET_REQUIRED_ATTENTION_CLASSES_COUNT_QUERY_NAME,
      ],
      awaitRefetchQueries: true,
    }
  );

  const onRemoveClassClick = async (id: number) => {
    setClassId(id);

    await removeClass({
      variables: {
        id,
      },
    });
  };

  return (
    <PageLayout title="Календар" contentClassName="calendar-page__content">
      <div className="calendar-page__calendar">
        <Calendar
          fullscreen={false}
          onSelect={(e) => {
            setDate(e.format('YYYY-MM-DD'));
          }}
        />
        <List.Item.Meta
          title={
            <Button
              type={'primary'}
              onClick={() => setAddModalOpen(true)}
              size="large"
            >
              {tr('calendar.classes.add.label')}
            </Button>
          }
        />
      </div>
      <div className="calendar-page__students_list">
        <div className="calendar-page__students_actual_list__wrapper">
          <div className="calendar-page__students_actual_list">
            {!!data?.getClasses?.length && (
              <Spinner spinning={loading}>
                <List
                  itemLayout="horizontal"
                  dataSource={data?.getClasses?.map((item: any) => {
                    return {
                      ...item,
                      id: item.id,
                      from: item.time.split(':').slice(0, 2).join(':'),
                      to: addDurationToTime(
                        item.time,
                        item.classGroup.duration
                      ),
                    };
                  })}
                  renderItem={(item: any) => (
                    <List.Item
                      actions={[
                        ![
                          ClassStatus.Confirmed,
                          ClassStatus.Cancelled,
                        ].includes(item.status) && (
                          <Button
                            type="primary"
                            onClick={() => onRemoveClassClick(item.id)}
                            style={{ backgroundColor: 'red' }}
                            loading={classId === item.id && removeClassLoading}
                          >
                            Видалити
                          </Button>
                        ),
                        ![
                          ClassStatus.Confirmed,
                          ClassStatus.Cancelled,
                        ].includes(item.status) && (
                          <Button
                            type="primary"
                            onClick={() => {
                              setMoveModalOpen(true);
                              setClassId(item.id);
                            }}
                          >
                            Перенести
                          </Button>
                        ),
                      ].filter(Boolean)}
                    >
                      <Skeleton avatar title={false} loading={false} active>
                        <List.Item.Meta
                          title={
                            <span>
                              {item.classGroup.name}{' '}
                              <ClassGroupTrialTag
                                isTrial={item.classGroup.isTrial}
                              />
                            </span>
                          }
                          description={tr(
                            'calendar.classes.list.item.description',
                            {
                              time: `${item.from} - ${item.to}`,
                            }
                          )}
                        />
                      </Skeleton>
                    </List.Item>
                  )}
                />
              </Spinner>
            )}
          </div>
        </div>
      </div>
      {date && (
        <AddClassModal
          open={addModalOpen}
          setOpen={setAddModalOpen}
          date={date}
        />
      )}
      <MoveClassModal
        open={moveModalOpen}
        setOpen={setMoveModalOpen}
        classId={classId as number}
      />
    </PageLayout>
  );
};
