export enum UserRole {
  Admin = 'Admin',
  Teacher = 'Teacher',
  Student = 'Student',
}

export enum ClassStatus {
  Scheduled = 'Scheduled',
  Confirmed = 'Confirmed',
  Cancelled = 'Cancelled',
  NotConfirmed = 'NotConfirmed',
}

export enum ClassCanceledType {
  StudentCanceled = 'StudentCanceled',
  TeacherCanceled = 'TeacherCanceled',
}

export enum ClassApprovalStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Pending = 'Pending',
}
