import React, { FC, useLayoutEffect } from 'react';
import { Header } from '../Header';
import { Content } from 'antd/es/layout/layout';
import './PageLayout.css';

export interface PageLayoutProps {
  pageClassName?: string;
  contentClassName?: string;

  title: string;

  showHeader?: boolean;

  children: React.ReactNode;
}

export const PageLayout: FC<PageLayoutProps> = ({
  pageClassName,
  contentClassName,

  title,

  showHeader = true,

  children,
}) => {
  useLayoutEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      {showHeader && <Header />}
      <Content className={`page-layout ${pageClassName ?? ''}`}>
        <div className={`page-layout__content ${contentClassName ?? ''}`}>
          {children}
        </div>
      </Content>
    </>
  );
};
