import { FC } from 'react';
import './Cell.css';

export const Cell: FC<any> = ({ className, children, ...restProps }) => {
  return (
    <td className={`cell ${className ?? ''}`} {...restProps}>
      {children}
    </td>
  );
};
