import React from 'react';
import { useForm } from 'antd/es/form/Form';
import { Button, Form, Input, Modal } from 'antd';
import { useMutation } from '@apollo/client';
import { ClassStatus } from '../../../../../lib/types';
import { ClassCanceledTypeSelector } from '../../selectors/ClassCanceledTypeSelector';
import { tr } from '../../../../../lib/translate';
import { DatePicker } from '../../../../../components/DatePicker';
import {
  GET_CLASSES_QUERY_NAME,
  GET_REQUIRED_ATTENTION_CLASSES_COUNT_QUERY_NAME,
  UPDATE_CLASS_MUTATION,
} from '../../../graphql';
import { GET_CLASSES_REPORT_QUERY_NAME } from '../../../../reports/graphql';

export interface ReportCanceledModallProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  classId: string | null;
}

export const ReportCanceledModal: React.FC<ReportCanceledModallProps> = ({
  open,
  setOpen,
  classId,
}) => {
  const handleCancel = () => {
    setOpen(false);
  };
  const [updateClass, { loading }] = useMutation(UPDATE_CLASS_MUTATION, {
    refetchQueries: [
      GET_CLASSES_QUERY_NAME,
      GET_CLASSES_REPORT_QUERY_NAME,
      GET_REQUIRED_ATTENTION_CLASSES_COUNT_QUERY_NAME,
    ],
    awaitRefetchQueries: true,
  });

  const [form] = useForm();

  const onFinish = async (values: any) => {
    await updateClass({
      variables: {
        id: classId,
        status: ClassStatus.Cancelled,
        canceledReason: values.canceledReason,
        canceledType: values.canceledType,
        cancellationNotifiedAt: values.cancellationNotifiedAt,
      },
    });

    setOpen(false);
  };

  return (
    <Modal
      open={open}
      title={tr('my-classes.classes.table.actions.report-cancellation.title')}
      onCancel={handleCancel}
      confirmLoading={loading}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {tr('modal.buttons.cancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={form.submit}>
          {tr('modal.buttons.confirm')}
        </Button>,
      ]}
    >
      <Form
        name="basic"
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item
          label={tr(
            'my-classes.classes.table.actions.report-cancellation.fields.cancellationType.label'
          )}
          name="canceledType"
          rules={[
            {
              required: true,
              message: tr(
                'my-classes.classes.table.actions.report-cancellation.fields.cancellationType.errors.required'
              ),
            },
          ]}
        >
          <ClassCanceledTypeSelector />
        </Form.Item>
        <Form.Item
          label={tr(
            'my-classes.classes.table.actions.report-cancellation.fields.cancellation-notified-at.label'
          )}
          name="cancellationNotifiedAt"
          rules={[
            {
              required: true,
              message: tr(
                'my-classes.classes.table.actions.report-cancellation.fields.cancellation-notified-at.errors.required'
              ),
            },
          ]}
        >
          <DatePicker showTime showSecond={false} format={'YYYY-MM-DD HH:mm'} />
        </Form.Item>
        <Form.Item
          label={tr(
            'my-classes.classes.table.actions.report-cancellation.fields.canceled-reason.label'
          )}
          name="canceledReason"
          rules={[
            {
              required: true,
              message: tr(
                'my-classes.classes.table.actions.report-cancellation.fields.canceled-reason.errors.required'
              ),
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
