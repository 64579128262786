import './index.css';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getAccessToken } from './modules/auth/utils';

export const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  cache: new InMemoryCache(),
  headers: {
    authorization: getAccessToken() ? `Bearer ${getAccessToken()}` : '',
  },
});
