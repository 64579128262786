import React, { FC, ReactNode, useState } from 'react';
import './ReportList.css';
import { Space } from 'antd';
import { useQuery } from '@apollo/client';
import { ClassStatus } from '../../../../../lib/types';

import dayjs, { Dayjs } from 'dayjs';
import { ReportListFilters } from './ReportListFilters';
import { List } from '../../../../../components/List';
import { GET_CLASSES_REPORT_QUERY } from '../../../graphql';

export interface ReportListProps {
  renderItem: (record: any) => ReactNode;
  renderTotal: (response: any, loading: boolean) => ReactNode;
  allowedFilters?: string[];
}

export const ReportList: FC<ReportListProps> = ({
  renderItem,
  renderTotal,
  allowedFilters,
}) => {
  // filters
  const [dateFrom, setDateFrom] = useState<Dayjs>(dayjs());
  const [dateTo, setDateTo] = useState<Dayjs>(dayjs());
  const [studentsIds, setStudentsIds] = useState<number[]>([]);
  const [teachersIds, setTeachersIds] = useState<number[]>([]);
  const [classStatuses, setClassStatuses] = useState<ClassStatus[]>([]);

  const { data, loading } = useQuery(GET_CLASSES_REPORT_QUERY, {
    variables: {
      dateFrom: dateFrom.format('YYYY-MM-DD'),
      dateTo: dateTo.format('YYYY-MM-DD'),
      studentIdIn: studentsIds,
      teacherIdIn: teachersIds,
      statusIn: classStatuses,
      limit: 1000,
    },
  });

  return (
    <div className="report-list">
      <Space
        style={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <ReportListFilters
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          studentsIds={studentsIds}
          setStudentsIds={setStudentsIds}
          teachersIds={teachersIds}
          setTeachersIds={setTeachersIds}
          classStatuses={classStatuses}
          setClassStatuses={setClassStatuses}
          allowedFilters={allowedFilters}
        />
        {renderTotal(data?.getClassesReport, loading)}
      </Space>
      <List
        className="report-list__list"
        itemLayout="horizontal"
        loading={loading}
        dataSource={data?.getClassesReport?.classes?.map((item: any) => ({
          ...item,
        }))}
        renderItem={renderItem}
      />
    </div>
  );
};
