import React, { useEffect } from 'react';
import { Button, Checkbox, Form, Input, InputNumber, notification } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { tr } from '../../../../../lib/translate';
import { TimePicker } from '../../../../../components/TimePicker';
import { DatePicker } from '../../../../../components/DatePicker';
import { ClassGroupsSelector } from '../../../../class-groups/components/selectors/ClassGroupsSelector';
import { ADD_CLASS_MUTATION, GET_CLASSES_QUERY_NAME } from '../../../graphql';
import { GET_CLASSES_REPORT_QUERY_NAME } from '../../../../reports/graphql';
import {
  GET_CLASS_GROUP_QUERY,
  GET_CLASS_GROUPS_QUERY_NAME,
} from '../../../../class-groups/graphql';
import { BaseFormModal } from '../../../../../containers/BaseModal';
import { useForm, useWatch } from 'antd/es/form/Form';
import {
  durationToMinutes,
  formatFullName,
  minutesToTime,
} from '../../../../../lib/utils';
import { ClassCanceledTypeSelector } from '../../selectors/ClassCanceledTypeSelector';
import { ClassApprovalStatus, ClassStatus } from '../../../../../lib/types';
import { ClassGroupType } from '../../../../class-groups/namespace';

export interface AddClassModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  date?: string;
}

export const AddClassModal: React.FC<AddClassModalProps> = ({
  open,
  setOpen,
  date,
}) => {
  const handleCancel = () => {
    setOpen(false);
  };
  const [addClass, { loading }] = useMutation(ADD_CLASS_MUTATION, {
    refetchQueries: [
      GET_CLASS_GROUPS_QUERY_NAME,
      GET_CLASSES_QUERY_NAME,
      GET_CLASSES_REPORT_QUERY_NAME,
    ],
    awaitRefetchQueries: true,
  });

  const [form] = useForm();
  const status = useWatch('status', form);
  const classGroupId = useWatch('classGroupId', form);

  const { data: classGroupData } = useQuery(GET_CLASS_GROUP_QUERY, {
    variables: {
      id: classGroupId,
    },
    skip: !classGroupId,
  });

  useEffect(() => {
    if (!classGroupData?.getClassGroup) {
      return;
    }

    form.setFieldValue(
      'actualDuration',
      durationToMinutes(classGroupData?.getClassGroup.duration)
    );
  }, [classGroupData?.getClassGroup?.id]);

  const onFinish = async (values: any) => {
    const classHappenedFields = {
      topic: values.topic,
      description: values.description,
      homework: values.homework,
      status: ClassStatus.Confirmed,
      approvalStatus: ClassApprovalStatus.Approved,
      actualDuration: minutesToTime(values.actualDuration),
      studentsAttendance:
        classGroupData?.getClassGroup?.type === ClassGroupType.Individual
          ? [classGroupData?.getClassGroup?.studentDetails[0].student.id]
          : values.studentsAttendance,
    };
    const classCanceledFields = {
      status: ClassStatus.Cancelled,
      canceledReason: values.canceledReason,
      canceledType: values.canceledType,
      cancellationNotifiedAt: values.cancellationNotifiedAt,
    };

    let extraClassFields = {};

    if (status === 'happened') {
      extraClassFields = classHappenedFields;
    } else if (status === 'canceled') {
      extraClassFields = classCanceledFields;
    }

    await addClass({
      variables: {
        classGroupId: values.classGroupId,
        time: values.time.format('HH:mm'),
        date: date || values.date.format('YYYY-MM-DD'),
        ...extraClassFields,
      },
    });

    if (status === 'canceled') {
      notification.info({
        message: 'Урок успішно додано',
        description: 'Дякую, ми повідомили адміністратора, очікуйте скасування',
      });
    } else {
      notification.success({
        message: 'Урок успішно додано',
      });
    }
  };

  return (
    <BaseFormModal
      form={form}
      open={open}
      setOpen={setOpen}
      onFinish={onFinish}
      title={tr('classes.add-class.title')}
      onCancel={handleCancel}
      confirmLoading={loading}
      cancelText={tr('classes.add-class.actions.cancel.label')}
      okText={tr('classes.add-class.actions.add.label')}
    >
      {!date && (
        <Form.Item
          label={tr('classes.add-class.fields.date.label')}
          name="date"
          rules={[
            {
              required: true,
              message: tr('classes.add-class.fields.date.errors.required'),
            },
          ]}
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
      )}
      <Form.Item
        label={'Група або студент'}
        name="classGroupId"
        rules={[
          {
            required: true,
            message: 'Виберіть групу або студента',
          },
        ]}
      >
        <ClassGroupsSelector />
      </Form.Item>
      <Form.Item
        label={tr('classes.add-class.fields.time.label')}
        name="time"
        rules={[
          {
            required: true,
            message: tr('classes.add-class.fields.time.errors.required'),
          },
        ]}
      >
        <TimePicker showSecond={false} minuteStep={30} format="HH:mm" />
      </Form.Item>
      <Form.Item
        label={tr('classes.add-class.fields.status.label')}
        name="status"
        rules={[
          {
            message: tr('classes.add-class.fields.status.errors.required'),
          },
        ]}
      >
        <Button
          onClick={() => form.setFieldValue('status', 'canceled')}
          style={{
            marginRight: 20,
            backgroundColor: '#ffa39e',
          }}
        >
          Заняття не відбулось
        </Button>
        <Button
          onClick={() => form.setFieldValue('status', 'happened')}
          style={{ backgroundColor: '#b7eb8f' }}
        >
          Заняття було
        </Button>
      </Form.Item>

      {status === 'happened' && (
        <>
          <Form.Item
            label={tr(
              'my-classes.classes.table.actions.updateStatus.fields.topic.label'
            )}
            name="topic"
            rules={[
              {
                message: tr(
                  'my-classes.classes.table.actions.updateStatus.fields.topic.errors.required'
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={tr(
              'my-classes.classes.table.actions.updateStatus.fields.description.label'
            )}
            name="description"
            rules={[
              {
                message: tr(
                  'my-classes.classes.table.actions.updateStatus.fields.description.errors.required'
                ),
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label={tr(
              'my-classes.classes.table.actions.updateStatus.fields.homework.label'
            )}
            name="homework"
            rules={[
              {
                message: tr(
                  'my-classes.classes.table.actions.updateStatus.fields.homework.errors.required'
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={tr(
              'my-classes.classes.table.actions.updateStatus.fields.actual-duration.label'
            )}
            name="actualDuration"
            rules={[
              {
                required: true,
                message: tr(
                  'my-classes.classes.table.actions.updateStatus.fields.actual-duration.errors.required'
                ),
              },
            ]}
          >
            <InputNumber min={0} max={120} />
          </Form.Item>

          {classGroupData?.getClassGroup &&
            classGroupData?.getClassGroup?.type !==
              ClassGroupType.Individual && (
              <Form.Item
                name="studentsAttendance"
                label="Присутність"
                tooltip="Позначте студента який був присутній на занятті, якщо студент не був присутній не відмічайте його"
              >
                <Checkbox.Group>
                  {classGroupData?.getClassGroup?.studentDetails?.map(
                    (detail: any) => {
                      return (
                        <Checkbox
                          value={detail.student.id}
                          style={{ lineHeight: '32px' }}
                        >
                          {formatFullName(detail.student)}
                        </Checkbox>
                      );
                    }
                  )}
                </Checkbox.Group>
              </Form.Item>
            )}
        </>
      )}
      {status === 'canceled' && (
        <>
          <Form.Item
            label={tr(
              'my-classes.classes.table.actions.report-cancellation.fields.cancellationType.label'
            )}
            name="canceledType"
            rules={[
              {
                required: true,
                message: tr(
                  'my-classes.classes.table.actions.report-cancellation.fields.cancellationType.errors.required'
                ),
              },
            ]}
          >
            <ClassCanceledTypeSelector />
          </Form.Item>
          <Form.Item
            label={tr(
              'my-classes.classes.table.actions.report-cancellation.fields.cancellation-notified-at.label'
            )}
            name="cancellationNotifiedAt"
            rules={[
              {
                required: true,
                message: tr(
                  'my-classes.classes.table.actions.report-cancellation.fields.cancellation-notified-at.errors.required'
                ),
              },
            ]}
          >
            <DatePicker
              showTime
              showSecond={false}
              format={'YYYY-MM-DD HH:mm'}
            />
          </Form.Item>
          <Form.Item
            label={tr(
              'my-classes.classes.table.actions.report-cancellation.fields.canceled-reason.label'
            )}
            name="canceledReason"
            rules={[
              {
                required: true,
                message: tr(
                  'my-classes.classes.table.actions.report-cancellation.fields.canceled-reason.errors.required'
                ),
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </>
      )}
    </BaseFormModal>
  );
};
