import { Select } from 'antd';
import React from 'react';
import { SelectProps } from 'antd/es/select';
import { ClassCanceledType } from '../../../../../lib/types';
import { tr } from '../../../../../lib/translate';

interface ClassStatusSelectorProps extends SelectProps {}

export const ClassCanceledTypeSelector: React.FC<ClassStatusSelectorProps> = (
  props
) => {
  const options = [
    {
      value: ClassCanceledType.TeacherCanceled,
      label: tr('classes.canceled-type.teacher-canceled.label'),
    },
    {
      value: ClassCanceledType.StudentCanceled,
      label: tr('classes.canceled-type.student-canceled.label'),
    },
  ];

  return <Select options={options} {...props} />;
};
