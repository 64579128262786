import { makeGqlRequest } from '../../lib/graphql';

export const {
  request: GET_CLASSES_REPORT_QUERY,
  name: GET_CLASSES_REPORT_QUERY_NAME,
} = makeGqlRequest(`
  query GetClassesReport(
    $dateFrom: String!
    $dateTo: String!
    $studentIdIn: [Int!]
    $teacherIdIn: [Int!]
    $statusIn: [ClassStatus!]
  ) {
    getClassesReport(
      dateFrom: $dateFrom
      dateTo: $dateTo
      studentIdIn: $studentIdIn
      teacherIdIn: $teacherIdIn
      statusIn: $statusIn
    ) {
      teacherPayAmountSum
      studentPaymentsSum
      classes {
        id
        status
        studentDetails {
          student {
            id
            createdAt
            updatedAt
            firstName
            lastName
          }
          payment
        }
        teacher {
          id
          createdAt
          updatedAt
          firstName
          lastName
        }
        classGroup {
          id
          isTrial
          name
          duration
        }
        date
        time
        teacherPayAmount
      }
    }
  }
`);
