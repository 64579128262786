import React from 'react';
import { ClassGroupTable } from '../../modules/class-groups/components/tables/ClassGroupTable';
import { AddClassGroupModal } from '../../modules/class-groups/components/modals/AddClassGroupModal';
import { Button } from 'antd';
import { EditClassGroupModal } from '../../modules/class-groups/components/modals/EditClassGroupModal';
import { PageLayout } from '../../containers/PageLayout';

export const ClassGroupsPage = () => {
  const [addClassGroupModalOpen, setAddClassGroupModalOpen] =
    React.useState(false);
  const [editClassGroupModalOpen, setEditClassGroupModalOpen] =
    React.useState(false);
  const [classGroupModalId, setClassGroupModalId] = React.useState<
    number | null
  >(null);

  const onRowClick = (record: any) => {
    setClassGroupModalId(record.id);
    setEditClassGroupModalOpen(true);
  };

  const renderExtraButtons = () => {
    return (
      <Button type={'primary'} onClick={() => setAddClassGroupModalOpen(true)}>
        Add group
      </Button>
    );
  };

  return (
    <PageLayout title="Студенти">
      <ClassGroupTable
        onRowClick={onRowClick}
        renderExtraButtons={renderExtraButtons}
      />
      <AddClassGroupModal
        open={addClassGroupModalOpen}
        setOpen={setAddClassGroupModalOpen}
      />
      <EditClassGroupModal
        open={editClassGroupModalOpen}
        setOpen={setEditClassGroupModalOpen}
        classGroupId={classGroupModalId}
      />
    </PageLayout>
  );
};
