import React from 'react';
import { ClassGroupBaseModal } from '../ClassGroupBaseModal';
import { useMutation } from '@apollo/client';
import {
  ADD_CLASS_GROUP_MUTATION,
  GET_CLASS_GROUPS_QUERY_NAME,
} from '../../../graphql';

export interface AddClassGroupModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const AddClassGroupModal: React.FC<AddClassGroupModalProps> = ({
  open,
  setOpen,
}) => {
  const [mutate, { loading: addLoading }] = useMutation(
    ADD_CLASS_GROUP_MUTATION,
    {
      refetchQueries: [GET_CLASS_GROUPS_QUERY_NAME],
      awaitRefetchQueries: true,
    }
  );
  const onFinish = async (values: any) => {
    await mutate({
      variables: {
        ...values,
        studentDetails: values.students.map((item: any) => ({
          ...item,
        })),
      },
    });
    setOpen(false);
  };

  return (
    <ClassGroupBaseModal
      open={open}
      setOpen={setOpen}
      onFinish={onFinish}
      title="Add group"
      okText="Add"
      loading={false}
      confirmLoading={addLoading}
      successMessage="Student added successfully"
    />
  );
};
