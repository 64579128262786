import { TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';
import { tr } from '../../lib/translate';

export const rangePresets: TimeRangePickerProps['presets'] = [
  { label: tr('common.date-presets.today.label'), value: [dayjs(), dayjs()] },
  {
    label: tr('common.date-presets.yesterday.label'),
    value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')],
  },
  {
    label: tr('common.date-presets.last-7-days.label'),
    value: [dayjs().add(-7, 'd'), dayjs()],
  },
  {
    label: tr('common.date-presets.last-14-days.label'),
    value: [dayjs().add(-14, 'd'), dayjs()],
  },
  {
    label: tr('common.date-presets.last-30-days.label'),
    value: [dayjs().add(-30, 'd'), dayjs()],
  },
];
