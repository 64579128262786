import React, { useState } from 'react';
import { ClassModal, ClassModalProps } from '../ClassModal';
import { Button } from 'antd';
import { useMutation } from '@apollo/client';
import { ClassApprovalStatus } from '../../../../../lib/types';
import {
  GET_CLASSES_QUERY_NAME,
  GET_REQUIRED_ATTENTION_CLASSES_COUNT_QUERY_NAME,
  UPDATE_CLASS_MUTATION,
} from '../../../graphql';
import { GET_CLASSES_REPORT_QUERY_NAME } from '../../../../reports/graphql';
import { ApproveClassDetailsModal } from '../ApproveClassDetailsModal.tsx';

export interface ClassesPageClassModalProps
  extends Omit<ClassModalProps, 'footer'> {}

export const ClassesPageClassModal: React.FC<ClassesPageClassModalProps> = ({
  ...props
}) => {
  const [showApproveDetailsModal, setShowApproveDetailsModal] = useState(false);
  const [approveUpdateClass, { loading: approveLoading }] = useMutation(
    UPDATE_CLASS_MUTATION,
    {
      refetchQueries: [
        GET_CLASSES_QUERY_NAME,
        GET_CLASSES_REPORT_QUERY_NAME,
        GET_REQUIRED_ATTENTION_CLASSES_COUNT_QUERY_NAME,
      ],
      awaitRefetchQueries: true,
    }
  );

  const onApprove = async ({ teacherPayAmount }: any) => {
    await approveUpdateClass({
      variables: {
        id: props.classId,
        approvalStatus: ClassApprovalStatus.Approved,
        teacherPayAmount,
      },
    });

    props.setOpen(false);
  };
  const onReject = async () => {
    await approveUpdateClass({
      variables: {
        id: props.classId,
        approvalStatus: ClassApprovalStatus.Rejected,
      },
    });
  };

  const onApproveClick = async () => {
    setShowApproveDetailsModal(true);
  };

  const renderFooter = (record: any) => {
    return (
      <div style={{ marginTop: 20 }}>
        {record.approvalStatus !== ClassApprovalStatus.Approved && (
          <>
            <Button
              loading={approveLoading}
              type="primary"
              onClick={onApproveClick}
              style={{ marginRight: 10 }}
            >
              Approve
            </Button>
            <ApproveClassDetailsModal
              open={showApproveDetailsModal}
              setOpen={setShowApproveDetailsModal}
              onFinish={onApprove}
              defaultTeacherPayAmount={
                record.teacherPayAmount || record.classGroup.teacherRate
              }
            />
          </>
        )}
        {record.approvalStatus !== ClassApprovalStatus.Rejected && (
          <Button loading={approveLoading} type="primary" onClick={onReject}>
            Reject
          </Button>
        )}
      </div>
    );
  };

  return <ClassModal {...props} renderFooter={renderFooter} />;
};
