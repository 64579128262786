import { Select } from 'antd';
import React from 'react';
import { SelectProps } from 'antd/es/select';
import { tr } from '../../../../../lib/translate';
import { ClassGroupDuration } from '../../../namespace';

interface ClassGroupDurationSelectorProps extends SelectProps {}

export const ClassGroupDurationSelector: React.FC<
  ClassGroupDurationSelectorProps
> = (props) => {
  const options = [
    {
      label: tr('classes.duration.one-hour.label'),
      value: ClassGroupDuration.OneHour,
    },
    {
      label: tr('classes.duration.one-and-half-hour.label'),
      value: ClassGroupDuration.OneAndHalfHour,
    },
    {
      label: tr('classes.duration.two-hours.label'),
      value: ClassGroupDuration.TwoHours,
    },
  ];

  return (
    <Select
      options={options}
      placeholder={tr('common.class-duration-selector.placeholder')}
      {...props}
    />
  );
};
