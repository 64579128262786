import { Select } from 'antd';
import React from 'react';
import { SelectProps } from 'antd/es/select';
import { UserRole } from '../../../../../lib/types';

interface UserRoleSelectorProps extends SelectProps {}

export const UserRoleSelector: React.FC<UserRoleSelectorProps> = (props) => {
  const options = [
    { value: UserRole.Student, label: 'Student' },
    { value: UserRole.Teacher, label: 'Teacher' },
    { value: UserRole.Admin, label: 'Admin' },
  ];

  return <Select options={options} {...props} />;
};
