import React, { FC } from 'react';
import { useForm } from 'antd/es/form/Form';
import { Form } from 'antd';

interface EditableRowProps {
  index: number;
}

export const EditableContext = React.createContext<any>(null);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const EditableRow: FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
