import React, { useState } from 'react';
import { Button, List } from 'antd';
import { AddUserModal } from '../../modules/users/components/modals/AddUserModal';
import { UsersTable } from '../../modules/users/components/tables/UsersTable';
import { PageLayout } from '../../containers/PageLayout';

export const UsersPage = () => {
  const [addTeacherModalOpen, setAddTeacherModalOpen] = useState(false);

  return (
    <PageLayout title="Користувачі">
      <UsersTable />
      <List.Item.Meta
        title={
          <Button
            type={'primary'}
            onClick={() => setAddTeacherModalOpen(true)}
            style={{ marginLeft: '20px' }}
          >
            Add user
          </Button>
        }
      />
      <AddUserModal
        open={addTeacherModalOpen}
        setOpen={setAddTeacherModalOpen}
      />
    </PageLayout>
  );
};
