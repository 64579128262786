import { Modal, ModalProps } from 'antd';
import { FC } from 'react';
import { Spinner } from '../../components/Spinner';

export interface BaseModalProps extends ModalProps {
  loading?: boolean;
}

export const BaseModal: FC<BaseModalProps> = ({
  loading,
  children,
  ...props
}) => {
  return (
    <Modal {...props}>
      <Spinner spinning={loading}>{children}</Spinner>
    </Modal>
  );
};
