import { Button, Select } from 'antd';
import React, { useState } from 'react';
import './UsersSelector.css';
import { useQuery } from '@apollo/client';
import { UserRole } from '../../../../../lib/types';
import { SelectProps } from 'antd/es/select';
import { RoleTag } from '../../tags/RoleTag';
import { tr } from '../../../../../lib/translate';
import { GET_USERS_QUERY } from '../../../graphql';
import { AddUserModal } from '../../modals/AddUserModal';

export interface UsersSelectorProps extends SelectProps {
  roleIn?: UserRole[];
  showRoles?: boolean;
  selectingOtherOptionsDisabled?: boolean;

  showAddUser?: boolean;
  showAddUserRole?: UserRole;
}

export const UsersSelector: React.FC<UsersSelectorProps> = ({
  roleIn,
  showRoles,
  selectingOtherOptionsDisabled,
  showAddUser,
  showAddUserRole,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [value, setValue] = useState<any>(null);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const { data } = useQuery(GET_USERS_QUERY, {
    variables: {
      roleIn,
      limit: 1000,
    },
  });
  const options = data?.getUsers
    .filter((user: any) => {
      if (!searchValue) {
        return true;
      }

      return `${user.firstName} ${user.lastName}`
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    })
    .map((user: any) => ({
      label: (
        <span>
          {`${user.firstName} ${user.lastName}`}{' '}
          {showRoles && <RoleTag role={user.role} style={{ marginLeft: 10 }} />}
        </span>
      ),
      value: user.id,
      disabled:
        selectingOtherOptionsDisabled && value && !value.includes(user.id),
    }));

  return (
    <>
      <Select
        options={options}
        placeholder={tr('common.users-selector.placeholder')}
        dropdownRender={(menu) => (
          <div>
            {menu}
            {showAddUser && (
              <div className="users-selector__add-user">
                <Button onClick={() => setAddUserOpen(true)}>Add user</Button>
              </div>
            )}
          </div>
        )}
        onSearch={(value) => {
          setSearchValue(value);
        }}
        searchValue={searchValue}
        filterOption={false}
        showSearch={true}
        {...props}
        onChange={(value, option) => {
          setValue(value);

          if (props.onChange) {
            props.onChange(value, option);
          }
        }}
      />
      <AddUserModal
        open={addUserOpen}
        setOpen={setAddUserOpen}
        role={showAddUserRole}
      />
    </>
  );
};
