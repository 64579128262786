import { setAccessToken } from '../../modules/auth/utils';
import { useMutation } from '@apollo/client';
import { STOP_ACTING_AS_MUTATION } from '../../modules/auth/graphql';
import { useEffect } from 'react';

export const StopActingAsPage = () => {
  const [stopActingAsMutate] = useMutation(STOP_ACTING_AS_MUTATION);

  const stopActingAs = async () => {
    const { data } = await stopActingAsMutate();

    setAccessToken(data.stopActingAs.token);

    window.location.href = '/users';
  };

  useEffect(() => {
    stopActingAs();
  }, []);

  return null;
};
