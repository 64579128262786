import React from 'react';
import { ClassGroupType } from '../../../namespace';
import { Tag, TagProps } from 'antd';

interface ClassGroupTypeTagProps extends TagProps {
  type: ClassGroupType;
}

export const ClassGroupTypeTag: React.FC<ClassGroupTypeTagProps> = ({
  type,
  ...props
}) => {
  const classGroupTypeToTagColor: Record<ClassGroupType, string> = {
    [ClassGroupType.Individual]: '#f50',
    [ClassGroupType.Group]: '#2db7f5',
    [ClassGroupType.Couple]: '#87d068',
  };

  return (
    <Tag color={classGroupTypeToTagColor[type]} {...props}>
      {type}
    </Tag>
  );
};
