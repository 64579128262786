import { makeGqlRequest } from '../../lib/graphql';

const classGroupsFieldsGql = `
    id
    name
    isTrial
    isTrialScheduled
    type
    duration
    studentDetails {
        student {
            id
            firstName
            lastName
        }
        rate
    }
    teacher {
        id
        firstName
        lastName
        rate
    }
    teacherRate    
`;

export const {
  request: GET_CLASS_GROUPS_QUERY,
  name: GET_CLASS_GROUPS_QUERY_NAME,
} = makeGqlRequest(`
    query getClassGroups(
        $studentIdIn: [Int!]
        $teacherIdIn: [Int!]
        $typeIn: [ClassGroupType!]
        $trialClassScheduled: Boolean
        $limit: Int
        $offset: Int
    ) {
        getClassGroups(
            studentIdIn: $studentIdIn
            teacherIdIn: $teacherIdIn
            typeIn: $typeIn
            trialClassScheduled: $trialClassScheduled
            limit: $limit
            offset: $offset
        ) {
            ${classGroupsFieldsGql}
        }
    }
`);

export const { request: ADD_CLASS_GROUP_MUTATION } = makeGqlRequest(`
    mutation addClassGroup(
        $name: String!
        $isTrial: Boolean
        $type: ClassGroupType!
        $teacherId: Int!
        $teacherRate: Float!
        $duration: ClassGroupDuration!
        $studentDetails: [AddClassGroupStudentDetailDto!]!
    ) {
        addClassGroup(
            name: $name
            isTrial: $isTrial
            type: $type
            teacherId: $teacherId
            teacherRate: $teacherRate
            duration: $duration
            studentDetails: $studentDetails
        ) {
            ${classGroupsFieldsGql}
        }
    }
`);

export const { request: EDIT_CLASS_GROUP_MUTATION } = makeGqlRequest(`
    mutation updateClassGroup(
        $id: Int!
        $name: String
        $isTrial: Boolean
        $type: ClassGroupType
        $teacherId: Int
        $teacherRate: Float
        $studentDetails: [AddClassGroupStudentDetailDto!]
    ) {
        updateClassGroup(
            id: $id
            name: $name
            isTrial: $isTrial
            type: $type
            teacherId: $teacherId
            teacherRate: $teacherRate
            studentDetails: $studentDetails
        ) {
            ${classGroupsFieldsGql}
        }
    }
`);

export const { request: GET_CLASS_GROUP_QUERY } = makeGqlRequest(`
    query getClassGroup(
        $id: Int!
    ) {
        getClassGroup(
            id: $id
        ) {
            ${classGroupsFieldsGql}
        }
    }
`);
