import { TableColumnType } from '../../components/Table/namespace';
import { formatFullName } from '../../lib/utils';
import { map } from 'lodash';
import { ClassGroupTypeTag } from './components/tags/ClassGroupTypeTag';
import { ClassGroupTrialTag } from './components/tags/ClassGroupTrialTag';

export const classGroupsColumns: TableColumnType[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    key: 'name',
    render: (record: any) => {
      return (
        <span>
          {record.name}{' '}
          <ClassGroupTrialTag
            isTrial={record.isTrial}
            trialScheduled={record.isTrialScheduled}
          />
        </span>
      );
    },
  },
  {
    title: 'Type',
    key: 'type',
    render: (record: any) => {
      return <ClassGroupTypeTag type={record.type} />;
    },
  },
  {
    title: 'Teacher',
    key: 'teacher',
    render: (record: any) => {
      return formatFullName(record.teacher);
    },
  },
  {
    title: 'Students',
    key: 'students',
    render: (record: any) => {
      const students = map(record.studentDetails, 'student');

      return map(students, formatFullName).join(', ');
    },
  },
];

export enum ClassGroupType {
  Individual = 'Individual',
  Group = 'Group',
  Couple = 'Couple',
}

export enum ClassGroupDuration {
  OneHour = 'OneHour',
  OneAndHalfHour = 'OneAndHalfHour',
  TwoHours = 'TwoHours',
}
