import React from 'react';
import { UserModal } from '../UserModal';
import { useMutation } from '@apollo/client';
import { CREATE_USER_MUTATION, GET_USERS_QUERY_NAME } from '../../../graphql';
import { UserRole } from '../../../../../lib/types';

export interface AddTeacherModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  role?: UserRole;
}

export const AddUserModal: React.FC<AddTeacherModalProps> = ({
  open,
  setOpen,
  role,
}) => {
  const [addUserMutate, { loading }] = useMutation(CREATE_USER_MUTATION, {
    refetchQueries: [GET_USERS_QUERY_NAME],
    awaitRefetchQueries: true,
  });

  const onSubmit = async (values: any) => {
    await addUserMutate({
      variables: {
        ...values,
      },
    });

    setOpen(false);
  };

  return (
    <UserModal
      open={open}
      setOpen={setOpen}
      onSubmit={onSubmit}
      loading={loading}
      title="Add user"
      okText="Add"
      role={role}
    />
  );
};
