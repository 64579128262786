import { makeGqlRequest } from '../../lib/graphql';

const classFields = `
    id
    createdAt
    updatedAt
    date
    status
    time
    canceledType
    canceledReason
    canceledAt
    cancellationNotifiedAt
    teacher {
        id
        createdAt
        updatedAt
        firstName
        lastName
        role
    }
    studentDetails {
        student {
            id
            createdAt
            updatedAt
            firstName
            lastName
            role
        }
        payment
    }
    topic
    description
    homework
    approvalStatus
    rejectedReason
    teacherPayAmount
    actualDuration
    classGroup {
        id
        name
        isTrial
        duration
        teacherRate
    }
`;

// classGroupId
// date
// time
// topic
// description
// homework
// status
// canceledType
// canceledReason
// canceledAt
// cancellationNotifiedAt
// approvalStatus
// rejectedReason
// actualDuration
// studentsAttendance
export const { request: ADD_CLASS_MUTATION } = makeGqlRequest(`
  mutation AddClass(
    $date: String!
    $time: String!
    $classGroupId: Int!
    $topic: String
    $description: String
    $homework: String
    $status: ClassStatus
    $canceledType: ClassCanceledType
    $canceledReason: String
    $cancellationNotifiedAt: DateTime
    $approvalStatus: ClassApprovalStatus
    $rejectedReason: String
    $actualDuration: String
    $studentsAttendance: [Int!]
  ) {
    addClass(
      date: $date
      time: $time
        classGroupId: $classGroupId
        topic: $topic
        description: $description
        homework: $homework
        status: $status
        canceledType: $canceledType
        canceledReason: $canceledReason
        cancellationNotifiedAt: $cancellationNotifiedAt
        approvalStatus: $approvalStatus
        rejectedReason: $rejectedReason
        actualDuration: $actualDuration
        studentsAttendance: $studentsAttendance
    ) {
        ${classFields}
   }
  }
`);

export const { request: GET_CLASSES_QUERY, name: GET_CLASSES_QUERY_NAME } =
  makeGqlRequest(`
  query GetClasses(
    $studentId: Int
    $teacherId: Int
    $limit: Int
    $offset: Int
    $date: String
    $dateFrom: String
    $dateTo: String
    $studentIdIn: [Int!]
    $teacherIdIn: [Int!]
    $statusIn: [ClassStatus!]
    $approvalStatusIn: [ClassApprovalStatus!]
    $requiredAttentionEq: Boolean
  ) {
    getClasses(
      studentId: $studentId
      teacherId: $teacherId
      limit: $limit
      offset: $offset
      date: $date
      dateFrom: $dateFrom
      dateTo: $dateTo
      studentIdIn: $studentIdIn
      teacherIdIn: $teacherIdIn
      statusIn: $statusIn
      approvalStatusIn: $approvalStatusIn
      requiredAttentionEq: $requiredAttentionEq
    ) {
     ${classFields}
    }
  }
`);

export const {
  request: GET_REQUIRED_ATTENTION_CLASSES_COUNT_QUERY,
  name: GET_REQUIRED_ATTENTION_CLASSES_COUNT_QUERY_NAME,
} = makeGqlRequest(`
  query GetRequiredAttentionClassesCount(
    $studentId: Int
    $teacherId: Int
    $limit: Int
    $offset: Int
    $date: String
    $dateFrom: String
    $dateTo: String
    $studentIdIn: [Int!]
    $teacherIdIn: [Int!]
  ) {
    getRequiredAttentionClassesCount(
      studentId: $studentId
      teacherId: $teacherId
      limit: $limit
      offset: $offset
      date: $date
      dateFrom: $dateFrom
      dateTo: $dateTo
      studentIdIn: $studentIdIn
      teacherIdIn: $teacherIdIn
    ) {
     count
    }
  }
`);

export const { request: GET_CLASS_QUERY } = makeGqlRequest(`
  query GetClass($id: Int!) {
    getClass(id: $id) {
        ${classFields}
    }
  }
`);

export const { request: UPDATE_CLASS_MUTATION } = makeGqlRequest(`
  mutation UpdateClass(
    $id: Int!
    $date: String
    $time: String
    $topic: String
    $description: String
    $homework: String
    $status: ClassStatus
    $canceledType: ClassCanceledType
    $canceledReason: String
    $cancellationNotifiedAt: DateTime
    $approvalStatus: ClassApprovalStatus
    $rejectedReason: String
    $actualDuration: String
    $studentsAttendance: [Int!]
    $teacherPayAmount: Float
  ) {
    updateClass(
      id: $id
        date: $date
        time: $time
      topic: $topic
      description: $description
      homework: $homework
      status: $status
      canceledType: $canceledType
      canceledReason: $canceledReason
      cancellationNotifiedAt: $cancellationNotifiedAt
        approvalStatus: $approvalStatus
        rejectedReason: $rejectedReason
        actualDuration: $actualDuration
        studentsAttendance: $studentsAttendance
        teacherPayAmount: $teacherPayAmount
    ) {
        ${classFields}
    }
  }
`);

export const { request: REMOVE_CLASS_MUTATION } = makeGqlRequest(`
  mutation RemoveClass($id: Int!) {
    removeClass(id: $id) {
      removed
    }
  }
`);
