import { Dayjs } from 'dayjs';
import { StudentsSelector } from '../../../../users/components/selectors/StudentsSelector';
import React, { FC } from 'react';
import { ClassStatusSelector } from '../../../../classes/components/selectors/ClassStatusSelector';
import { ClassStatus } from '../../../../../lib/types';
import { DatePicker, rangePresets } from '../../../../../components/DatePicker';
import { TeachersSelector } from '../../../../users/components/selectors/TeachersSelector';

export interface ReportListFiltersProps {
  dateFrom: Dayjs;
  dateTo: Dayjs;
  setDateFrom: (date: Dayjs) => void;
  setDateTo: (date: Dayjs) => void;
  studentsIds: number[];
  setStudentsIds: (studentsIds: number[]) => void;
  teachersIds: number[];
  setTeachersIds: (teachersIds: number[]) => void;
  classStatuses: ClassStatus[];
  setClassStatuses: (classStatuses: ClassStatus[]) => void;
  allowedFilters?: string[];
}

export const ReportListFilters: FC<ReportListFiltersProps> = ({
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  studentsIds,
  setStudentsIds,
  teachersIds,
  setTeachersIds,
  classStatuses,
  setClassStatuses,
  allowedFilters,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'space-between',
        rowGap: '11px',
        columnGap: '11px',
      }}
    >
      <DatePicker.RangePicker
        onChange={(event) => {
          const [dateFrom, dateTo] = event as Dayjs[];
          setDateFrom(dateFrom);
          setDateTo(dateTo);
        }}
        defaultValue={[dateFrom, dateTo]}
        presets={rangePresets}
        allowClear={false}
      />
      {allowedFilters?.includes('teachers') !== false && (
        <TeachersSelector
          mode="multiple"
          onChange={(teachersIds) => setTeachersIds(teachersIds)}
          value={teachersIds}
          style={{ minWidth: '300px' }}
          maxTagCount="responsive"
        />
      )}
      <StudentsSelector
        mode="multiple"
        onChange={(studentsIds) => setStudentsIds(studentsIds)}
        value={studentsIds}
        style={{ minWidth: '300px' }}
        maxTagCount="responsive"
      />
      <ClassStatusSelector
        mode="multiple"
        onChange={(classStatuses) => setClassStatuses(classStatuses)}
        value={classStatuses}
        style={{ minWidth: '200px' }}
        maxTagCount="responsive"
      />
    </div>
  );
};
