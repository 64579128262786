import { tr } from './translate';
import { ClassGroupDuration } from '../modules/class-groups/namespace';

export function formatFullName(user: any) {
  if (!user) {
    return `Undefined`;
  }
  if (!user.firstName && !user.lastName) {
    return `Unknown name`;
  }

  return `${user.firstName} ${user.lastName}`;
}

export function addDurationToTime(time: string, duration: ClassGroupDuration) {
  if (duration === ClassGroupDuration.OneHour) {
    const [hours, minutes] = time.split(':');

    return addZero([Number(hours) + 1, minutes].join(':'));
  }

  if (duration === ClassGroupDuration.OneAndHalfHour) {
    const [hours, minutes] = time.split(':');
    const newMin = Number(minutes) + 30;
    return addZero(
      [Number(hours) + (newMin === 60 ? 2 : 1), newMin === 60 ? 0 : 30].join(
        ':'
      )
    );
  }

  if (duration === ClassGroupDuration.TwoHours) {
    const [hours, minutes] = time.split(':');

    return addZero([Number(hours) + 2, minutes].join(':'));
  }
}

function addZero(time: string) {
  let result = time;
  const [h, m] = time.split(':');

  if (parseInt(h) < 10) {
    result = `0${h}:${m}`;
  }

  if (m === '0') {
    result = `${result.split(':')[0]}:00`;
  }

  return result;
}

export function durationToText(classDuration: ClassGroupDuration) {
  if (classDuration === ClassGroupDuration.OneHour) {
    return tr('classes.duration.one-hour.label');
  }
  if (classDuration === ClassGroupDuration.OneAndHalfHour) {
    return tr('classes.duration.one-and-half-hour.label');
  }
  if (classDuration === ClassGroupDuration.TwoHours) {
    return tr('classes.duration.two-hours.label');
  }
}

export function durationToDurationText(classDuration: ClassGroupDuration) {
  if (classDuration === ClassGroupDuration.OneHour) {
    return '1:00';
  }
  if (classDuration === ClassGroupDuration.OneAndHalfHour) {
    return '1:30';
  }
  if (classDuration === ClassGroupDuration.TwoHours) {
    return '2:00';
  }
}
export function durationToMinutes(classDuration: ClassGroupDuration) {
  if (classDuration === ClassGroupDuration.OneHour) {
    return 60;
  }
  if (classDuration === ClassGroupDuration.OneAndHalfHour) {
    return 90;
  }
  if (classDuration === ClassGroupDuration.TwoHours) {
    return 120;
  }
}

export function minutesToTime(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours}:${mins < 10 ? '0' + mins : mins}`;
}

export function timeToMinutes(time: string) {
  if (!time) {
    return '';
  }

  const [hours, minutes] = time.split(':');
  return Number(hours) * 60 + Number(minutes);
}

export function formatDateTime(dateStr: string | null | unknown) {
  if (!dateStr) {
    return null;
  }

  const date = new Date(dateStr as string);

  return date.toLocaleString();
}
