import React, { FC } from 'react';
import {
  DatePicker as AndDatePicker,
  DatePickerProps as AntdDatePickerProps,
} from 'antd';
import { tr } from '../../lib/translate';
import './DatePicker.css';

export type DatePickerProps = AntdDatePickerProps & {};

export const DatePicker: FC<DatePickerProps> & {
  WeekPicker: typeof AndDatePicker.WeekPicker;
  MonthPicker: typeof AndDatePicker.MonthPicker;
  YearPicker: typeof AndDatePicker.YearPicker;
  RangePicker: typeof AndDatePicker.RangePicker;
  TimePicker: typeof AndDatePicker.TimePicker;
  QuarterPicker: typeof AndDatePicker.QuarterPicker;
} = ({ className, ...props }) => {
  return (
    <AndDatePicker
      className={`date-picker ${className || ''}`}
      {...props}
      placeholder={tr('common.date-picker.placeholder')}
    />
  );
};

DatePicker.WeekPicker = AndDatePicker.WeekPicker;
DatePicker.MonthPicker = AndDatePicker.MonthPicker;
DatePicker.YearPicker = AndDatePicker.YearPicker;
DatePicker.RangePicker = AndDatePicker.RangePicker;
DatePicker.TimePicker = AndDatePicker.TimePicker;
DatePicker.QuarterPicker = AndDatePicker.QuarterPicker;
