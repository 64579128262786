import React from 'react';
import './StudentsTable.css';
import { Space } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { UserRole } from '../../../../../lib/types';
import { formatFullName } from '../../../../../lib/utils';
import { useCurrentUser } from '../../../../../hooks/useCurrentUser';
import { Table } from '../../../../../components/Table';
import { tr } from '../../../../../lib/translate';
import { TableColumnType } from '../../../../../components/Table/namespace';
import { GET_USERS_QUERY, UPDATE_USER_NOTE_MUTATION } from '../../../graphql';

export const StudentsTable = () => {
  const currentUser = useCurrentUser();

  const { data, loading } = useQuery(GET_USERS_QUERY, {
    variables: {
      roleIn: [UserRole.Student],
      assignedToId: currentUser.id,
      limit: 1000,
    },
  });

  const [updateUserNote] = useMutation(UPDATE_USER_NOTE_MUTATION, {});

  const columns: TableColumnType[] = [
    {
      title: tr('students-page.table.columns.name.label'),
      key: 'name',
      render: (_: any, record: any) => (
        <Space size="middle">{formatFullName(record)}</Space>
      ),
    },
    {
      title: tr('students-page.table.columns.notes.label'),
      dataIndex: 'note.text',
      key: 'notes',
      editable: true,
      render: (value, record) => {
        return <span>{record.note.text}</span>;
      },
    },
  ];

  const onEdit = async (record: any) => {
    await updateUserNote({
      variables: {
        userId: record.id,
        text: record.note.text ?? '',
      },
    });
  };

  return (
    <div className="students-table">
      <Table
        columns={columns}
        editable={true}
        dataSource={data?.getUsers?.map((user: any) => {
          return {
            ...user,
            key: user.id,
          };
        })}
        pagination={false}
        loading={loading}
        onEdit={onEdit}
      />
    </div>
  );
};
