import { Button, Form, Input, Layout, Typography } from 'antd';
import React from 'react';
import { useForm } from 'antd/es/form/Form';
import './LoginPage.css';
import { useMutation } from '@apollo/client';
import { LOGIN_MUTATION } from '../../modules/auth/graphql';
import { Spinner } from '../../components/Spinner';
import { PageLayout } from '../../containers/PageLayout';
import { setAccessToken } from '../../modules/auth/utils';

export const LoginPage = () => {
  const [form] = useForm();
  const [mutate, { loading: loginLoading }] = useMutation(LOGIN_MUTATION);

  const onFinish = async (values: any) => {
    const { errors, data } = await mutate({
      variables: {
        email: values.email,
        password: values.password,
      },
      errorPolicy: 'all',
    });

    if (data) {
      setAccessToken(data.login.token);
      window.location.href = '/';
    } else if (errors) {
      const [error] = errors;

      if (error.message === 'INVALID_DATA') {
        form.setFields([
          {
            name: 'email',
            errors: ['Неправильний емейл або пароль'],
          },
        ]);
      } else {
        throw error;
      }
    }
  };

  return (
    <PageLayout contentClassName="login-page" title="Логін" showHeader={false}>
      <Layout className="login-page__layout">
        <Typography.Title className="login-page__title">
          Haw.Eng for Teachers
        </Typography.Title>
        <Spinner tip="Перевіряємо дані..." spinning={loginLoading}>
          <Form
            name="basic"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="E-mail"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Введіть ваш емейл!',
                },
                {
                  type: 'email',
                  message: 'Введіть корректний емейл!',
                },
              ]}
              className="test"
            >
              <Input className="login-page_input" />
            </Form.Item>

            <Form.Item
              label="Пароль"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Введіть ваш пароль!',
                },
              ]}
            >
              <Input.Password className="login-page_input" />
            </Form.Item>

            <Form.Item className="login-page_submit-item">
              <Button type="primary" htmlType="submit">
                Ввійти
              </Button>
            </Form.Item>
          </Form>
        </Spinner>
      </Layout>
    </PageLayout>
  );
};
