import React from 'react';
import { MyClassesClassesTable } from '../../modules/classes/components/tables/MyClassesClassesTable';
import { PageLayout } from '../../containers/PageLayout';

export const MyClassesPage = () => {
  return (
    <PageLayout title="Мої заняття">
      <MyClassesClassesTable />
    </PageLayout>
  );
};
