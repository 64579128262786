import React, { ReactNode } from 'react';
import './ClassModal.css';
import { Button, Modal } from 'antd';
import { useQuery } from '@apollo/client';
import {
  formatDateTime,
  formatFullName,
  timeToMinutes,
} from '../../../../../lib/utils';
import { ClassApprovalStatusTag } from '../../tags/ClassApprovalStatusTag';
import { ClassStatusTag } from '../../tags/ClassStatusTag';
import { GET_CLASS_QUERY } from '../../../graphql';

export interface ClassModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  classId: string | null;
  renderFooter?: (record: any) => ReactNode;
}

export const ClassModal: React.FC<ClassModalProps> = ({
  open,
  setOpen,
  classId,
  renderFooter,
}) => {
  const handleCancel = () => {
    setOpen(false);
  };
  const { data, loading } = useQuery(GET_CLASS_QUERY, {
    skip: !classId,
    variables: {
      id: classId,
    },
  });

  const classInfo = data?.getClass;

  return (
    <Modal
      className={'class-modal'}
      open={open}
      title={`Class ${classInfo?.id}`}
      onCancel={handleCancel}
      confirmLoading={loading}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
      ]}
    >
      <span className="class-modal-item-title">
        Status: <ClassStatusTag status={classInfo?.status} />
      </span>
      <span className="class-modal-item-title">
        Approval status:{' '}
        <ClassApprovalStatusTag approvalStatus={classInfo?.approvalStatus} />
      </span>
      <span className="class-modal-item-title">
        Date: <span className="class-modal-item-text">{classInfo?.date}</span>
      </span>
      <span className="class-modal-item-title">
        Status:{' '}
        <span className="class-modal-item-text">{classInfo?.status}</span>
      </span>
      <span className="class-modal-item-title">
        Time: <span className="class-modal-item-text">{classInfo?.time}</span>
      </span>
      <span className="class-modal-item-title">
        Duration:{' '}
        <span className="class-modal-item-text">{classInfo?.duration}</span>
      </span>
      {
        <span className="class-modal-item-title">
          Canceled type:{' '}
          <span className="class-modal-item-text">
            {classInfo?.canceledType}
          </span>
        </span>
      }
      {
        <span className="class-modal-item-title">
          Canceled reason:{' '}
          <span className="class-modal-item-text">
            {classInfo?.canceledReason}
          </span>
        </span>
      }
      {
        <span className="class-modal-item-title">
          Canceled at:{' '}
          <span className="class-modal-item-text">
            {formatDateTime(classInfo?.canceledAt)}
          </span>
        </span>
      }
      {
        <span className="class-modal-item-title">
          Notification time:{' '}
          <span className="class-modal-item-text">
            {formatDateTime(classInfo?.cancellationNotifiedAt)}
          </span>
        </span>
      }
      <span className="class-modal-item-title">
        Teacher ID:{' '}
        <span className="class-modal-item-text">
          {formatFullName(classInfo?.teacher)}
        </span>
      </span>
      <span className="class-modal-item-title">
        Student ID:{' '}
        <span className="class-modal-item-text">
          {formatFullName(classInfo?.student)}
        </span>
      </span>
      {
        <span className="class-modal-item-title">
          Topic:{' '}
          <span className="class-modal-item-text">{classInfo?.topic}</span>
        </span>
      }
      {
        <span className="class-modal-item-title">
          Actual duration:{' '}
          <span className="class-modal-item-text">
            {timeToMinutes(classInfo?.actualDuration)}
          </span>
        </span>
      }
      {
        <span className="class-modal-item-title">
          Description:{' '}
          <span className="class-modal-item-text">
            {classInfo?.description}
          </span>
        </span>
      }
      {
        <span className="class-modal-item-title">
          Homework:{' '}
          <span className="class-modal-item-text">{classInfo?.homework}</span>
        </span>
      }
      {classInfo && renderFooter && renderFooter(classInfo)}
    </Modal>
  );
};
