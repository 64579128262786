import React from 'react';
import { Layout } from 'antd';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CURRENT_USER_QUERY } from './modules/auth/graphql';
import { LoadingPage } from './pages/LoadingPage';
import { getRoutes } from './getRoutes';

function App() {
  const { data, loading } = useQuery(CURRENT_USER_QUERY, {});

  if (loading) {
    return <LoadingPage key="loading" loading={true} />;
  }

  const routes = getRoutes(data?.me);
  const router = createBrowserRouter(routes);

  return (
    <LoadingPage loading={loading} key="loading">
      <Layout style={{ height: '100vh', maxWidth: '100%' }}>
        <RouterProvider router={router} />
      </Layout>
    </LoadingPage>
  );
}

export default App;
