import { client } from '../../client';

export function getAccessToken() {
  return localStorage.getItem('token');
}

export function setAccessToken(token: string) {
  localStorage.setItem('token', token);
}

export function removeAccessToken() {
  localStorage.removeItem('token');
}

export async function login(token: string) {
  setAccessToken(token);

  await client.resetStore();

  window.location.href = '/';
}

export async function logout() {
  await client.resetStore();

  removeAccessToken();

  window.location.href = '/login';
}
