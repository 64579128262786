import React, { FC } from 'react';
import { Space } from 'antd';
import { Table, TableProps } from '../Table';
import { TableWithFiltersFilterType } from './namespace';

export interface TableWithFiltersProps extends TableProps {
  filters: TableWithFiltersFilterType[];
  allowedFilters?: string[];
  renderExtraButtons?: () => React.ReactNode;
}

export const TableWithFilters: FC<TableWithFiltersProps> = ({
  filters,
  allowedFilters,
  renderExtraButtons,
  ...props
}) => {
  const appliedFilters = filters.filter(
    (filter) => allowedFilters?.includes(filter.name) ?? true
  );

  return (
    <>
      {appliedFilters.length ? (
        <Space
          style={{
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <div>{appliedFilters.map((filter) => filter.component)}</div>
          {renderExtraButtons && renderExtraButtons()}
        </Space>
      ) : null}
      <Table {...props} />
    </>
  );
};
