import { Avatar, Badge, Menu, Tooltip } from 'antd';
import { Header as AntdHeader } from 'antd/es/layout/layout';
import React from 'react';
import './Header.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LogoutOutlined, StopOutlined, UserOutlined } from '@ant-design/icons';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { tr } from '../../lib/translate';
import { UserRole } from '../../lib/types';
import { useQuery } from '@apollo/client';
import { GET_REQUIRED_ATTENTION_CLASSES_COUNT_QUERY } from '../../modules/classes/graphql';

export const Header = () => {
  const user = useCurrentUser();
  const location = useLocation();
  const selectedKey = location.pathname.replace('/', '');
  const { data, loading } = useQuery(
    GET_REQUIRED_ATTENTION_CLASSES_COUNT_QUERY,
    {
      skip: user.role !== UserRole.Admin,
    }
  );

  console.log(data, loading);

  let items = [
    {
      key: '',
      label: tr('calendar.menu-title'),
    },
    {
      key: 'my-classes',
      label: tr('my-classes.menu-title'),
    },
    {
      key: 'students',
      label: tr('students.menu-title'),
    },
    {
      key: 'report',
      label: tr('report-page.menu-title'),
    },
    {
      key: 'users',
      label: 'Users',
      danger: true,
      onlyForAdmin: true,
    },
    {
      key: 'classes',
      label: 'Classes',
      danger: true,
      onlyForAdmin: true,
    },
    {
      key: 'classes/required-attention',
      label: (
        <Badge
          count={data?.getRequiredAttentionClassesCount?.count ?? 1}
          size="small"
          offset={[10, 0]}
        >
          <span
            style={{
              color:
                selectedKey === 'classes/required-attention'
                  ? 'white'
                  : '#ff4d4f',
            }}
          >
            To review
          </span>
        </Badge>
      ),
      danger: true,
      onlyForAdmin: true,
    },
    {
      key: 'reports',
      label: 'Reports',
      danger: true,
      onlyForAdmin: true,
    },
    {
      key: 'groups',
      label: 'Students',
      danger: true,
      onlyForAdmin: true,
    },
  ];

  const navigate = useNavigate();

  const onSelect = (key: string) => {
    navigate('/' + key);
  };

  if (user.role !== UserRole.Admin) {
    items = items.filter((item) => {
      return !item.onlyForAdmin;
    });
  }

  return (
    <AntdHeader className="header">
      <Menu
        className="header__menu"
        theme="dark"
        mode="horizontal"
        selectedKeys={[selectedKey]}
        items={items}
        onSelect={(e) => {
          return onSelect(e.key);
        }}
      />
      <div className="header__user">
        <span className="header__user-name">
          {user.firstName || 'Unnamed'} {user.lastName}
        </span>
        <Avatar size="large" icon={<UserOutlined />} />
      </div>
      {!user.isActingAs && (
        <Link
          to="/logout"
          className="header__logout_link"
          style={{ fontSize: '24px' }}
        >
          <Tooltip title="Logout" color="#FF5C98">
            <LogoutOutlined color="#FF5C98" />
          </Tooltip>
        </Link>
      )}
      {user.isActingAs && (
        <Link
          to="/stop-acting-as"
          className="header__logout_link"
          style={{ fontSize: '24px' }}
        >
          <Tooltip title="Stop acting as " color="#FF5C98">
            <StopOutlined color="#FF5C98" />
          </Tooltip>
        </Link>
      )}
    </AntdHeader>
  );
};
