import { Select } from 'antd';
import React from 'react';
import { SelectProps } from 'antd/es/select';
import { useQuery } from '@apollo/client';
import { GET_CLASS_GROUPS_QUERY } from '../../../graphql';
import { useCurrentUser } from '../../../../../hooks/useCurrentUser';

interface ClassGroupTypeSelectorProps extends SelectProps {
  showTrialScheduledGroups?: boolean;
}

export const ClassGroupsSelector: React.FC<ClassGroupTypeSelectorProps> = ({
  showTrialScheduledGroups,
  ...props
}) => {
  const currentUser = useCurrentUser();
  const { data, loading } = useQuery(GET_CLASS_GROUPS_QUERY, {
    variables: {
      teacherIdIn: [currentUser.id],
    },
  });

  const options = data?.getClassGroups
    ?.filter((classGroup: any) => {
      if (showTrialScheduledGroups) {
        return true;
      }

      return !classGroup.isTrialScheduled;
    })
    .map((item: any) => ({
      value: item.id,
      label: item.name,
    }));

  return (
    <Select
      loading={loading}
      options={options}
      placeholder="Виберіть групу або студента"
      {...props}
    />
  );
};
