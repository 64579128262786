import React, { useEffect } from 'react';
import { useForm, useWatch } from 'antd/es/form/Form';
import { Checkbox, Form, Input, InputNumber, Space, Typography } from 'antd';
import { ClassGroupTypeSelector } from '../../selectors/ClassGroupTypeSelector';
import { StudentsSelector } from '../../../../users/components/selectors/StudentsSelector';
import { readUsersFromCache } from '../../../../users/graphql';
import './ClassGroupBaseModal.css';
import { formatFullName } from '../../../../../lib/utils';
import { TeachersSelector } from '../../../../users/components/selectors/TeachersSelector';
import { ClassGroupType } from '../../../namespace';
import { tr } from '../../../../../lib/translate';
import { ClassGroupDurationSelector } from '../../selectors/ClassDurationSelector';
import {
  BaseFormModal,
  BaseFormModalProps,
} from '../../../../../containers/BaseModal';

export interface ClassGroupBaseModalProps extends BaseFormModalProps {
  partial?: boolean;
  title: string;
  okText: string;
}

export const ClassGroupBaseModal: React.FC<ClassGroupBaseModalProps> = ({
  open,
  setOpen,
  title,
  okText,
  ...props
}) => {
  const [form] = useForm();
  const studentsIds = useWatch('studentsIds', form) ?? [];
  const type = useWatch('type', form) as ClassGroupType;

  const selectedStudents = readUsersFromCache(studentsIds, [
    'id',
    'firstName',
    'lastName',
  ]);

  useEffect(() => {
    const studentsIds = form.getFieldValue('studentsIds') as number[];
    const students = form.getFieldValue('students');

    const value = studentsIds?.map((studentId) => {
      const currentValue = students?.find(
        (item: any) => item.studentId === studentId
      );

      return {
        studentId,
        rate: 0,
        ...currentValue,
      };
    });

    form.setFieldValue('students', value);
  }, [selectedStudents]);

  const studentsMaxLengthMap = {
    [ClassGroupType.Individual]: 1,
    [ClassGroupType.Couple]: 2,
    [ClassGroupType.Group]: 25,
  };

  return (
    <BaseFormModal
      open={open}
      setOpen={setOpen}
      title={title}
      cancelText={'Cancel'}
      okText={okText}
      form={form}
      {...props}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Name is required',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Trial" name="isTrial" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item
        label="Type"
        name="type"
        rules={[
          {
            required: true,
            message: 'Type is required',
          },
        ]}
      >
        <ClassGroupTypeSelector />
      </Form.Item>
      <Form.Item
        label={tr('classes.add-class.fields.duration.label')}
        name="duration"
        rules={[
          {
            required: true,
            message: tr('classes.add-class.fields.duration.errors.required'),
          },
        ]}
      >
        <ClassGroupDurationSelector />
      </Form.Item>
      <Form.Item
        label="Teacher"
        name="teacherId"
        rules={[
          {
            required: true,
            message: 'Select teacher',
          },
        ]}
      >
        <TeachersSelector showAddUser={true} />
      </Form.Item>
      <Form.Item
        label="Teacher rate"
        name="teacherRate"
        rules={[
          {
            required: true,
            message: "Select teacher's rate",
          },
        ]}
      >
        <InputNumber placeholder="Rate" min={0} addonAfter="UAH" />
      </Form.Item>
      <Form.Item
        label="Students"
        name="studentsIds"
        rules={[
          {
            required: true,
            message: 'Select students',
          },
        ]}
      >
        <StudentsSelector
          mode="multiple"
          selectingOtherOptionsDisabled={
            studentsIds?.length >= studentsMaxLengthMap[type]
          }
          showAddUser={true}
        />
      </Form.Item>

      <Form.List name="students">
        {(fields) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              const student = selectedStudents[key];

              return (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 8,
                  }}
                  align="baseline"
                  className="class-group-base-modal__students-item-wrapper"
                >
                  <Typography.Text>{formatFullName(student)}</Typography.Text>
                  <Form.Item
                    {...restField}
                    label="Rate"
                    name={[name, 'rate']}
                    rules={[{ required: true, message: 'Missing rate' }]}
                  >
                    <InputNumber placeholder="Rate" min={0} addonAfter="UAH" />
                  </Form.Item>
                </Space>
              );
            })}
          </>
        )}
      </Form.List>
    </BaseFormModal>
  );
};
