import React from 'react';
import { ClassStatus } from '../../../../../lib/types';
import { Tag } from 'antd';
import { tr } from '../../../../../lib/translate';

interface ClassStatusTagProps {
  status: ClassStatus;
}

export const ClassStatusTag: React.FC<ClassStatusTagProps> = ({ status }) => {
  const statusToTagColor = {
    [ClassStatus.Confirmed]: 'green',
    [ClassStatus.Scheduled]: 'blue',
    [ClassStatus.Cancelled]: 'red',
    [ClassStatus.NotConfirmed]: 'orange',
  };
  const statusToLabel = {
    [ClassStatus.Confirmed]: tr('classes.status.confirmed.label'),
    [ClassStatus.Scheduled]: tr('classes.status.scheduled.label'),
    [ClassStatus.Cancelled]: tr('classes.status.cancelled.label'),
    [ClassStatus.NotConfirmed]: tr('classes.status.not-confirmed.label'),
  };

  return <Tag color={statusToTagColor[status]}>{statusToLabel[status]}</Tag>;
};
