import React from 'react';
import { PageLayout } from '../../containers/PageLayout';
import Spitz from '../../assets/images/spitz.png';
import './NotFoundPage.css';
import { Link } from 'react-router-dom';
import { useCurrentUser } from '../../hooks/useCurrentUser';

export const NotFoundPage = () => {
  const currentUser = useCurrentUser();

  return (
    <PageLayout
      contentClassName="not-found-page__content"
      title="404"
      showHeader={!!currentUser}
    >
      <div className="not-found-page__content-main">
        <span className="not-found-page__content-four">4</span>
        <img alt="spitz" className="not-found-page__spitz" src={Spitz} />
        <span className="not-found-page__content-four">4</span>
      </div>
      <span className="not-found-page__content-text">
        oops... page not found
      </span>
      <Link to="/" className="not-found-page__content-back-link">
        Go back
      </Link>
    </PageLayout>
  );
};
