import React, { ReactNode } from 'react';
import { ColumnsType } from 'antd/es/table';
import { tr } from '../../../../../lib/translate';
import { ClassStatusTag } from '../../tags/ClassStatusTag';
import { ClassApprovalStatusTag } from '../../tags/ClassApprovalStatusTag';
import {
  addDurationToTime,
  formatFullName,
  timeToMinutes,
} from '../../../../../lib/utils';
import dayjs from 'dayjs';
import { sumBy } from 'lodash';
import { ClassGroupTrialTag } from '../../../../class-groups/components/tags/ClassGroupTrialTag';

export type GetClassesColumnsOptions = {
  onCellClick?: (record: any) => void;
  allowedColumns?: string[];
  renderActions?: (record: any) => ReactNode;
};

export function getClassesColumns(options: GetClassesColumnsOptions) {
  const { allowedColumns, renderActions } = options;
  const renderCell = (record: any, children: ReactNode) => {
    return children;
  };
  const baseColumn: ColumnsType<any>[0] = {};

  const columns: Array<ColumnsType<any>[0] & { visible?: boolean }> = [
    {
      title: tr('classes.table.columns.id'),
      key: 'id',
      width: '50px',
      fixed: true,
      render: (_, record) => renderCell(record, <span>{record.id}</span>),
      visible: allowedColumns?.includes('id'),
      ...baseColumn,
    },
    {
      title: 'Група',
      key: 'classGroupName',
      width: '200px',
      fixed: true,
      render: (_, record) =>
        renderCell(
          record,
          <span>
            {record.classGroup.name}{' '}
            <ClassGroupTrialTag isTrial={record.classGroup.isTrial} />
          </span>
        ),
      visible: allowedColumns?.includes('classGroupName'),
      ...baseColumn,
    },
    {
      title: tr('classes.table.columns.status'),
      key: 'status',
      width: '150px',
      render: (_, record) =>
        renderCell(record, <ClassStatusTag status={record.status} />),
      visible: allowedColumns?.includes('status'),
      ...baseColumn,
    },
    {
      title: tr('classes.table.columns.approval-status'),
      key: 'approvalStatus',
      width: '180px',
      render: (_, record) =>
        renderCell(
          record,
          <ClassApprovalStatusTag approvalStatus={record.approvalStatus} />
        ),
      visible: allowedColumns?.includes('status'),
      ...baseColumn,
    },
    {
      title: tr('classes.table.columns.teacher-name'),
      key: 'teacherName',
      width: '200px',
      render: (_, record) => renderCell(record, formatFullName(record.teacher)),
      visible: allowedColumns?.includes('teacherName'),
      ...baseColumn,
    },
    {
      title: tr('classes.table.columns.date'),
      width: '120px',
      render: (_, record) => renderCell(record, record.date),
      visible: allowedColumns?.includes('date'),
      ...baseColumn,
    },
    {
      title: tr('classes.table.columns.time'),
      key: 'time',
      width: '150px',
      render: (_, record) => {
        const from = record.time.split(':').slice(0, 2).join(':');
        const to = addDurationToTime(record.time, record.classGroup.duration);

        return renderCell(record, `${from} - ${to}`);
      },
      visible: allowedColumns?.includes('time'),
      ...baseColumn,
    },
    {
      title: tr('classes.table.columns.actual-duration'),
      width: '150px',
      render: (_, record) =>
        renderCell(record, timeToMinutes(record.actualDuration)),
      visible: allowedColumns?.includes('actualDuration'),
      ...baseColumn,
    },
    {
      title: tr('classes.table.columns.topic'),
      width: '150px',
      render: (_, record) => renderCell(record, record.topic),
      visible: allowedColumns?.includes('topic'),
      ...baseColumn,
    },
    {
      title: tr('classes.table.columns.teacher-pay-amount'),
      width: '150px',
      render: (_, record) =>
        renderCell(record, `${record.teacherPayAmount} UAH`),
      visible: allowedColumns?.includes('teacherPayAmount'),
      ...baseColumn,
    },
    {
      title: tr('classes.table.columns.students-payment'),
      width: '150px',
      render: (_, record) =>
        renderCell(record, `${sumBy(record.studentDetails, 'payment')} UAH`),
      visible: allowedColumns?.includes('studentsPayment'),
      ...baseColumn,
    },
    {
      title: tr('classes.table.columns.description'),
      width: '250px',
      render: (_, record) => renderCell(record, record.description),
      visible: allowedColumns?.includes('description'),
      ...baseColumn,
    },
    {
      title: tr('classes.table.columns.homework'),
      width: '250px',
      render: (_, record) => renderCell(record, record.homework),
      visible: allowedColumns?.includes('homework'),
      ...baseColumn,
    },
    {
      title: tr('classes.table.columns.canceled-type'),
      width: '150px',
      render: (_, record) => renderCell(record, record.canceledType),
      visible: allowedColumns?.includes('canceledType'),
      ...baseColumn,
    },
    {
      title: tr('classes.table.columns.canceled-reason'),
      width: '250px',
      render: (_, record) => renderCell(record, record.canceledReason),
      visible: allowedColumns?.includes('canceledReason'),
      ...baseColumn,
    },
    {
      title: tr('classes.table.columns.cancellation-notified-at'),
      width: '200px',
      render: (_, record) =>
        renderCell(
          record,
          record.cancellationNotifiedAt
            ? dayjs(record.cancellationNotifiedAt).format('YYYY-MM-DD HH:mm')
            : null
        ),
      visible: allowedColumns?.includes('cancellationNotifiedAt'),
      ...baseColumn,
    },
    {
      title: tr('classes.table.columns.actions'),
      key: 'actions',
      fixed: 'right',
      width: 450,
      // visible: renderActions ? allowedColumns?.includes('actions') : false,
      // turn it off
      visible: false,
      render: (_, record) => {
        if (!renderActions) {
          return null;
        }

        return renderActions(record);
      },
      ...baseColumn,
    },
  ];

  return columns;
}
