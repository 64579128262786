import React from 'react';
import { ReportList } from '../../modules/reports/components/list/ReportList';
import { List } from '../../components/List';
import { addDurationToTime } from '../../lib/utils';
import { Typography } from 'antd';
import { tr } from '../../lib/translate';
import { Spinner } from '../../components/Spinner';
import { ClassStatusTag } from '../../modules/classes/components/tags/ClassStatusTag';
import { PageLayout } from '../../containers/PageLayout';
import { ClassGroupTrialTag } from '../../modules/class-groups/components/tags/ClassGroupTrialTag';

export const MyReportPage = () => {
  const renderItem = (record: any) => (
    <List.Item>
      <List.Item.Meta
        title={
          <span>
            {`Група: ${record.classGroup.name}`}{' '}
            <ClassGroupTrialTag isTrial={record.classGroup.isTrial} />
            {`- ${record.teacherPayAmount} UAH`}{' '}
            <ClassStatusTag status={record.status} />
          </span>
        }
        description={`${record.date}: ${record.time} - ${addDurationToTime(
          record.time,
          record.classGroup.duration
        )}`}
      />
    </List.Item>
  );

  const renderTotal = (response: any, loading: boolean) => (
    <Spinner spinning={loading} tip={null}>
      <Typography.Text style={{ fontWeight: 'bold', fontSize: 20 }}>
        {tr('report-page.total', {
          amount: response?.teacherPayAmountSum ?? '',
        })}
      </Typography.Text>
    </Spinner>
  );

  return (
    <PageLayout title="Звіт">
      <ReportList
        renderItem={renderItem}
        renderTotal={renderTotal}
        allowedFilters={[]}
      />
    </PageLayout>
  );
};
