import { Select } from 'antd';
import React from 'react';
import { SelectProps } from 'antd/es/select';
import { ClassGroupType } from '../../../namespace';

interface ClassGroupTypeSelectorProps extends SelectProps {}

export const ClassGroupTypeSelector: React.FC<ClassGroupTypeSelectorProps> = (
  props
) => {
  const options = [
    { value: ClassGroupType.Individual, label: 'Individual' },
    { value: ClassGroupType.Group, label: 'Group' },
    { value: ClassGroupType.Couple, label: 'Couple' },
  ];

  return (
    <Select options={options} placeholder="Select group type" {...props} />
  );
};
