import { LoginPage } from './pages/LoginPage';
import { CalendarPage } from './pages/CalendarPage';
import { MyClassesPage } from './pages/MyClassesPage';
import { StudentsPage } from './pages/StudentsPage';
import { MyReportPage } from './pages/MyReportPage';
import { UsersPage } from './pages/UsersPage';
import { ClassesPage } from './pages/ClassesPage';
import { ReportsPage } from './pages/ReportsPage';
import { ClassGroupsPage } from './pages/ClassGroupsPage';
import { NotFoundPage } from './pages/NotFoundPage';
import React from 'react';
import { UserRole } from './lib/types';
import { LogoutPage } from './pages/LogoutPage';
import { StopActingAsPage } from './pages/StopActingAsPage';
import { RequiredAttentionClassesPage } from './pages/RequiredAttentionClassesPage';

const loginRoute = {
  path: '/login',
  element: <LoginPage />,
};
const loginNotFoundRoute = {
  path: '*',
  element: <LoginPage />,
};
const stopActingAsRoute = {
  path: '/stop-acting-as',
  element: <StopActingAsPage />,
};

const logoutRoute = {
  path: '/logout',
  element: <LogoutPage />,
};
const notFoundRoute = {
  path: '*',
  element: <NotFoundPage />,
};

const teacherRoutes = [
  {
    path: '/',
    element: <CalendarPage />,
  },
  {
    path: '/my-classes',
    element: <MyClassesPage />,
  },
  {
    path: '/students',
    element: <StudentsPage />,
  },
  {
    path: '/report',
    element: <MyReportPage />,
  },
];

const adminRoutes = [
  {
    path: '/users',
    element: <UsersPage />,
  },
  {
    path: '/classes',
    element: <ClassesPage />,
  },
  {
    path: '/classes/required-attention',
    element: <RequiredAttentionClassesPage />,
  },
  {
    path: '/reports',
    element: <ReportsPage />,
  },
  {
    path: '/groups',
    element: <ClassGroupsPage />,
  },
];

export function getRoutes(currentUser: any) {
  if (!currentUser) {
    return [loginRoute, loginNotFoundRoute];
  }

  const routes = [notFoundRoute, stopActingAsRoute];

  routes.push(logoutRoute);

  if (currentUser.role === UserRole.Admin) {
    routes.push(...adminRoutes);
    routes.push(...teacherRoutes);
  } else if (currentUser.role === UserRole.Teacher) {
    routes.push(...teacherRoutes);
  }

  return routes;
}
