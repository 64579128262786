import React, { FC } from 'react';
import {
  TimePicker as AndTimePicker,
  TimePickerProps as AntdTimePickerProps,
} from 'antd';
import './TimePicker.css';
import { tr } from '../../lib/translate';

export interface TimePickerProps extends AntdTimePickerProps {}

export const TimePicker: FC<TimePickerProps> = ({ className, ...props }) => {
  return (
    <AndTimePicker
      onSelect={(time) => {
        props.onChange!(time, time.format('HH:mm'));
      }}
      className={`time-picker ${className || ''}`}
      {...props}
      placeholder={tr('common.time-picker.placeholder')}
    />
  );
};
