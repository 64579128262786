import React, { useState } from 'react';
import { ClassesTable } from '../ClassesTable';
import { ClassStatus } from '../../../../../lib/types';
import { Button } from 'antd';
import { AddClassModal } from '../../modals/AddClassModal';
import { UpdateClassStatusModal } from '../../modals/UpdateClassStatusModal';
import { ReportCanceledModal } from '../../modals/ReportCanceledModal';
import { ClassModal } from '../../modals/ClassModal';
import { tr } from '../../../../../lib/translate';
import { useCurrentUser } from '../../../../../hooks/useCurrentUser';

export const MyClassesClassesTable = () => {
  // modals
  const [addClassModalOpen, setAddClassModalOpen] = useState(false);
  const [updateClassStatusModalOpen, setUpdateClassStatusModalOpen] =
    useState(false);
  const [reportCanceledModalOpen, setReportCanceledModalOpen] = useState(false);
  const [classModalOpen, setClassModalOpen] = useState(false);
  const [classModalId, setClassModalId] = useState<string | null>(null);

  const renderActions = (record: any) => {
    const updateStatusEnabled = record.status === ClassStatus.NotConfirmed;
    const actionsVisible = ![
      ClassStatus.Confirmed,
      ClassStatus.Cancelled,
    ].includes(record.status);

    return (
      <>
        {actionsVisible && (
          <Button
            type="primary"
            style={{ marginRight: '10px', background: '#008000aa' }}
            onClick={(e) => {
              e.stopPropagation();
              setClassModalId(record.id);
              setUpdateClassStatusModalOpen(true);
            }}
            disabled={!updateStatusEnabled}
          >
            {tr('my-classes.classes.table.actions.updateStatus.label')}
          </Button>
        )}
        {actionsVisible && (
          <Button
            type="primary"
            style={{ background: '#FF0000cc' }}
            onClick={(e) => {
              e.stopPropagation();
              setReportCanceledModalOpen(true);
              setClassModalId(record.id);
            }}
          >
            {tr('my-classes.classes.table.actions.report-cancellation.label')}
          </Button>
        )}
      </>
    );
  };
  const renderExtraButtons = () => {
    return (
      <Button type={'primary'} onClick={() => setAddClassModalOpen(true)}>
        {tr('my-classes.classes.actions.addClass.label')}
      </Button>
    );
  };
  const user = useCurrentUser();

  const onRowClick = (record: any) => {
    setClassModalId(record.id);
    setClassModalOpen(true);
  };

  return (
    <>
      <ClassesTable
        renderActions={renderActions}
        renderExtraButtons={renderExtraButtons}
        allowedColumns={[
          'id',
          'classGroupName',
          'status',
          'name',
          'duration',
          'date',
          'time',
          'actualDuration',
          'actions',
        ]}
        allowedFilters={['dates', 'students', 'statuses']}
        onRowClick={onRowClick}
        queryVariables={{ teacherId: user.id }}
      />
      <AddClassModal open={addClassModalOpen} setOpen={setAddClassModalOpen} />
      <UpdateClassStatusModal
        open={updateClassStatusModalOpen}
        setOpen={setUpdateClassStatusModalOpen}
        classId={classModalId}
      />
      <ReportCanceledModal
        open={reportCanceledModalOpen}
        setOpen={setReportCanceledModalOpen}
        classId={classModalId}
      />
      <ClassModal
        open={classModalOpen}
        setOpen={setClassModalOpen}
        classId={classModalId}
      />
    </>
  );
};
