import { Select } from 'antd';
import React from 'react';
import { SelectProps } from 'antd/es/select';
import { ClassStatus } from '../../../../../lib/types';
import { tr } from '../../../../../lib/translate';

interface ClassStatusSelectorProps extends SelectProps {}

export const ClassStatusSelector: React.FC<ClassStatusSelectorProps> = (
  props
) => {
  const options = [
    {
      value: ClassStatus.Scheduled,
      label: tr('classes.status.scheduled.label'),
    },
    {
      value: ClassStatus.Confirmed,
      label: tr('classes.status.confirmed.label'),
    },
    {
      value: ClassStatus.Cancelled,
      label: tr('classes.status.cancelled.label'),
    },
    {
      value: ClassStatus.NotConfirmed,
      label: tr('classes.status.not-confirmed.label'),
    },
  ];

  return (
    <Select
      options={options}
      placeholder={tr('common.class-status-selector.placeholder')}
      {...props}
    />
  );
};
