import { FC } from 'react';
import { List as AntdList, ListProps as AntdListProps } from 'antd';
import './List.css';
import { Spinner } from '../Spinner';

interface ListProps<T> extends AntdListProps<T> {}

export const List: FC<ListProps<any>> & { Item: typeof AntdList.Item } = ({
  className,
  loading,
  ...props
}) => {
  return (
    <div className={`list ${className || ''}`}>
      <Spinner spinning={loading === true}>
        <AntdList
          itemLayout="horizontal"
          className={`list-content`}
          locale={{ emptyText: <div></div> }}
          {...props}
        />
      </Spinner>
    </div>
  );
};

List.Item = AntdList.Item;
