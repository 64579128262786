import React from 'react';
import { UserRole } from '../../../../../lib/types';
import { Tag, TagProps } from 'antd';

interface RoleTagProps extends TagProps {
  role: UserRole;
}

export const RoleTag: React.FC<RoleTagProps> = ({ role, ...props }) => {
  const roleToTagColor = {
    [UserRole.Admin]: 'red',
    [UserRole.Teacher]: 'blue',
    [UserRole.Student]: 'green',
  };

  return (
    <Tag color={roleToTagColor[role]} {...props}>
      {role}
    </Tag>
  );
};
