import React, { FC, ReactNode, useState } from 'react';
import './ClassesTable.css';
import { useQuery } from '@apollo/client';
import { ClassStatus } from '../../../../../lib/types';
import dayjs, { Dayjs } from 'dayjs';
import { getClassesColumns } from './columns';
import { GET_CLASSES_QUERY } from '../../../graphql';
import { TableWithFilters } from '../../../../../components/TableWithFilters';
import { DatePicker, rangePresets } from '../../../../../components/DatePicker';
import { StudentsSelector } from '../../../../users/components/selectors/StudentsSelector';
import { TeachersSelector } from '../../../../users/components/selectors/TeachersSelector';
import { ClassStatusSelector } from '../../selectors/ClassStatusSelector';

export interface ClassesTableProps {
  allowedColumns?: string[];
  allowedFilters?: string[];
  renderActions?: (record: any) => ReactNode;
  renderExtraButtons?: () => ReactNode;
  onRowClick?: (record: any) => void;
  queryVariables?: any;
}

export const ClassesTable: FC<ClassesTableProps> = ({
  renderExtraButtons,
  allowedFilters,
  onRowClick,
  queryVariables,
  ...props
}) => {
  // filters
  const [dateFrom, setDateFrom] = useState<Dayjs>(dayjs());
  const [dateTo, setDateTo] = useState<Dayjs>(dayjs());
  const [studentsIds, setStudentsIds] = useState<number[]>([]);
  const [teachersIds, setTeachersIds] = useState<number[]>([]);
  const [classStatuses, setClassStatuses] = useState<ClassStatus[]>([]);

  const { data, loading } = useQuery(GET_CLASSES_QUERY, {
    variables: {
      dateFrom: dateFrom.format('YYYY-MM-DD'),
      dateTo: dateTo.format('YYYY-MM-DD'),
      studentIdIn: studentsIds,
      teacherIdIn: teachersIds,
      statusIn: classStatuses,
      limit: 1000,
      ...queryVariables,
    },
  });

  const columns = getClassesColumns(props);

  return (
    <TableWithFilters
      filters={[
        {
          name: 'dates',
          component: (
            <DatePicker.RangePicker
              onChange={(event) => {
                const [dateFrom, dateTo] = event as Dayjs[];
                setDateFrom(dateFrom);
                setDateTo(dateTo);
              }}
              defaultValue={[dateFrom, dateTo]}
              presets={rangePresets}
              allowClear={false}
            />
          ),
        },
        {
          name: 'students',
          component: (
            <StudentsSelector
              mode="multiple"
              onChange={(studentsIds) => setStudentsIds(studentsIds)}
              value={studentsIds}
              style={{ minWidth: '300px', marginLeft: '10px' }}
              maxTagCount="responsive"
            />
          ),
        },
        {
          name: 'teachers',
          component: (
            <TeachersSelector
              mode="multiple"
              onChange={(teachersIds) => setTeachersIds(teachersIds)}
              value={teachersIds}
              style={{ minWidth: '300px', marginLeft: '10px' }}
              maxTagCount="responsive"
              showRoles={true}
            />
          ),
        },
        {
          name: 'classStatuses',
          component: (
            <ClassStatusSelector
              mode="multiple"
              onChange={(classStatuses) => setClassStatuses(classStatuses)}
              value={classStatuses}
              style={{ minWidth: '200px', marginLeft: '10px' }}
              maxTagCount="responsive"
            />
          ),
        },
      ]}
      allowedFilters={allowedFilters}
      renderExtraButtons={renderExtraButtons}
      columns={columns.filter((column) =>
        column.visible === undefined ? true : column.visible
      )}
      onRow={(record) => {
        return {
          onClick: () => {
            if (onRowClick) {
              onRowClick(record);
            }
          },
        };
      }}
      dataSource={data?.getClasses?.map((user: any) => {
        const colors = ['#88888888', '#FFFF0088', '#00FF0088'];

        return {
          ...user,
          key: user.id,
          background: colors[Math.floor(Math.random() * colors.length)],
        };
      })}
      loading={loading}
      pagination={false}
    />
  );
};
