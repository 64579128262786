import { FC } from 'react';
import { Form, Table as AntdTable, TableProps as AntdTableProps } from 'antd';
import './Table.css';
import { Spinner } from '../Spinner';
import { Cell } from './Cell/Cell';
import { EditableCell } from './EditableCell/EditableCell';
import { EditableRow } from './EditableRow';

export interface TableProps<T = any> extends AntdTableProps<T> {
  editable?: boolean;
  onEdit?: (record: T) => void;
}

export const Table: FC<TableProps<any>> = ({
  className,
  loading,
  editable,
  columns,
  onEdit,
  ...props
}) => {
  const finalColumns = columns!.map((col) => {
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        handleSave: (record: any) => {
          if (onEdit) {
            onEdit(record);
          }
        },
        ...col,
      }),
    };
  });
  const tableComponent = (
    <AntdTable
      className={`table-content`}
      columns={finalColumns as any}
      locale={{ emptyText: <div></div> }}
      components={{
        body: {
          cell: editable ? EditableCell : Cell,
          row: editable ? EditableRow : undefined,
        },
      }}
      pagination={false}
      scroll={{ y: '100%' }}
      {...props}
    />
  );

  return (
    <div className={`table ${className || ''}`}>
      <Spinner spinning={loading === true}>
        {editable && <Form className="table-form">{tableComponent}</Form>}
        {!editable && tableComponent}
      </Spinner>
    </div>
  );
};
