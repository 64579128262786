import React from 'react';
import { StudentsTable } from '../../modules/users/components/tables/StudentsTable';
import { PageLayout } from '../../containers/PageLayout';

export const StudentsPage = () => {
  return (
    <PageLayout title="Студенти">
      <StudentsTable />
    </PageLayout>
  );
};
