import React from 'react';
import { Tag } from 'antd';
import { ClassApprovalStatus } from '../../../../../lib/types';
import { tr } from '../../../../../lib/translate';

interface ClassApprovalStatusTagProps {
  approvalStatus: ClassApprovalStatus;
}

export const ClassApprovalStatusTag: React.FC<ClassApprovalStatusTagProps> = ({
  approvalStatus,
}) => {
  const classApprovalStatusToTagColor = {
    [ClassApprovalStatus.Approved]: 'green',
    [ClassApprovalStatus.Pending]: 'blue',
    [ClassApprovalStatus.Rejected]: 'red',
  };
  const classApprovalStatusToLabel = {
    [ClassApprovalStatus.Approved]: tr(
      'classes.approval-status.approved.label'
    ),
    [ClassApprovalStatus.Pending]: tr('classes.approval-status.pending.label'),
    [ClassApprovalStatus.Rejected]: tr(
      'classes.approval-status.rejected.label'
    ),
  };

  return (
    <Tag color={classApprovalStatusToTagColor[approvalStatus]}>
      {classApprovalStatusToLabel[approvalStatus]}
    </Tag>
  );
};
