import { client } from '../../client';
import { gql } from '@apollo/client';
import { makeGqlRequest } from '../../lib/graphql';

export const readUsersFromCache = (ids: string[], fields: string[]) => {
  const fieldsGql = fields.join('\n');

  return ids.map((id) => {
    return client.readFragment({
      id: `UserModel:${id}`,
      fragment: gql`
                 fragment UserModel on UserModel {
                    ${fieldsGql}
                  }
            `,
    });
  });
};

const userFields = `
      id
      createdAt
      updatedAt
      firstName
      lastName
      email
      role
      note {
        id
        text
      }
`;

export const { request: GET_USERS_QUERY, name: GET_USERS_QUERY_NAME } =
  makeGqlRequest(`
  query GetUsers(
    $roleIn: [UserRole!]
    $assignedToId: Int
    $limit: Int
    $offset: Int
  ) {
    getUsers(
      roleIn: $roleIn
      limit: $limit
      offset: $offset
      assignedToId: $assignedToId
    ) {
      ${userFields}
    }
  }
`);

export const { request: GET_USER_QUERY } = makeGqlRequest(`
  query GetUser($id: Int!) {
    getUser(id: $id) {
      ${userFields}
    }
  }
`);

export const { request: CREATE_USER_MUTATION } = makeGqlRequest(`
  mutation CreateUser(
    $firstName: String
    $lastName: String
    $email: String
    $password: String
    $role: UserRole!
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      role: $role
    ) {
      ${userFields}
    }
  }
`);

export const { request: UPDATE_USER_MUTATION } = makeGqlRequest(`
  mutation UpdateUser(
    $id: Int!
    $firstName: String
    $lastName: String
    $email: String
    $password: String
    $role: UserRole!
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
        password: $password
      role: $role
    ) {
     ${userFields}
    }
  }
`);

export const { request: ARCHIVE_USER_MUTATION } = makeGqlRequest(`
  mutation ArchiveUser(
    $id: Int!
  ) {
    archiveUser(
      id: $id
    ) {
     role
     archivedAt
     teacherClassesArchived
     groupClassesArchived
     coupleClassesArchived
     individualClassesArchived
     groupClassesKicked
     coupleClassesKicked
    }
  }
`);

export const { request: UPDATE_USER_NOTE_MUTATION } = makeGqlRequest(`
  mutation UpdateUserNote($userId: Int!, $text: String!) {
    updateUserNote(userId: $userId, text: $text) {
      id
      text
    }
  }
`);
