import { BaseModal, BaseModalProps } from './BaseModal';
import { useForm } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib';
import { Form, notification } from 'antd';
import { FC, useEffect } from 'react';

export interface BaseFormModalProps extends BaseModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;

  form?: FormInstance;

  initialValues?: any;

  onFinish: (values: any, form: FormInstance) => Promise<void> | void;

  successMessage?: string;
}

export const BaseFormModal: FC<BaseFormModalProps> = ({
  open,
  setOpen,

  form: formProp,

  initialValues,

  onFinish,
  onCancel,

  afterClose,

  successMessage,

  children,
  ...props
}) => {
  const [form] = useForm(formProp);

  useEffect(() => {
    if (initialValues && open) {
      form.setFieldsValue(initialValues);
    } else if (!open) {
      form.resetFields();
    }
  }, [JSON.stringify(initialValues), open]);

  const onFinishHandler = async (values: any) => {
    await onFinish(values, form);

    setOpen(false);

    if (successMessage) {
      notification.success({
        message: successMessage,
      });
    }
  };

  const onCancelHandler: typeof onCancel = (event) => {
    if (onCancel) {
      onCancel(event);
    }

    setOpen(false);
  };

  const afterCloseHandler = () => {
    form.resetFields();

    if (afterClose) {
      afterClose();
    }
  };

  return (
    <BaseModal
      open={open}
      onOk={form.submit}
      afterClose={afterCloseHandler}
      onCancel={onCancelHandler}
      {...props}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinishHandler}
        initialValues={initialValues}
      >
        {children}
      </Form>
    </BaseModal>
  );
};
