import { Spin, SpinProps } from 'antd';
import React from 'react';
import './Spinner.css';
import SpitzImage from './spitz.png';
import { tr } from '../../lib/translate';

export interface SpinnerProps extends SpinProps {}

export const Spinner: React.FC<SpinnerProps> = ({
  className,
  spinning,
  ...props
}) => {
  return (
    <>
      <div className={`spinner ${className || ''}`}></div>
      <Spin
        tip={tr('common.loading')}
        spinning={spinning === true}
        {...props}
        indicator={<img className="spitz" src={SpitzImage} />}
      />
    </>
  );
};
