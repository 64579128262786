import { useEffect } from 'react';
import { logout } from '../../modules/auth/utils';

export const LogoutPage = () => {
  useEffect(() => {
    logout();
  }, []);

  return null;
};
