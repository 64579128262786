import React from 'react';
import { ClassesTable } from '../../modules/classes/components/tables/ClassesTable';
import { ClassesPageClassModal } from '../../modules/classes/components/modals/ClassesPageClassModal';
import { PageLayout } from '../../containers/PageLayout';

export const RequiredAttentionClassesPage = () => {
  const [classModalOpen, setClassModalOpen] = React.useState(false);
  const [classModalId, setClassModalId] = React.useState<string | null>(null);

  const onRowClick = (record: any) => {
    setClassModalId(record.id);
    setClassModalOpen(true);
  };

  return (
    <PageLayout title="Заняття які потребують уваги">
      <ClassesTable
        onRowClick={onRowClick}
        allowedFilters={[]}
        queryVariables={{
          requiredAttentionEq: true,
        }}
      />
      <ClassesPageClassModal
        classId={classModalId}
        open={classModalOpen}
        setOpen={setClassModalOpen}
      />
    </PageLayout>
  );
};
