import React from 'react';
import {
  BaseFormModal,
  BaseFormModalProps,
} from '../../../../../containers/BaseModal';
import { Form, InputNumber } from 'antd';

export interface ApproveClassDetailsModalProps extends BaseFormModalProps {
  defaultTeacherPayAmount: number;
}

export const ApproveClassDetailsModal: React.FC<
  ApproveClassDetailsModalProps
> = ({ defaultTeacherPayAmount, ...props }) => {
  return (
    <BaseFormModal
      {...props}
      initialValues={{ teacherPayAmount: defaultTeacherPayAmount }}
    >
      <Form.Item
        label="Оплата викладачу за заняття"
        name="teacherPayAmount"
        rules={[
          {
            required: true,
            message: 'Введіть суму',
          },
        ]}
      >
        <InputNumber />
      </Form.Item>
    </BaseFormModal>
  );
};
