import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(data: { email: $email, password: $password }) {
      token
    }
  }
`;

export const ACT_AS_MUTATION = gql`
  mutation ActAs($userId: Int!) {
    actAs(userId: $userId) {
      token
    }
  }
`;

export const STOP_ACTING_AS_MUTATION = gql`
  mutation StopActingAs {
    stopActingAs {
      token
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    me {
      id
      createdAt
      updatedAt
      role
      email
      firstName
      lastName
      isActingAs
    }
  }
`;
