import React from 'react';
import './ReportsPage.css';
import { ReportList } from '../../modules/reports/components/list/ReportList';
import { List } from '../../components/List';
import { addDurationToTime, formatFullName } from '../../lib/utils';
import { Spinner } from '../../components/Spinner';
import { Typography } from 'antd';
import { tr } from '../../lib/translate';
import { sumBy } from 'lodash';
import { ClassStatusTag } from '../../modules/classes/components/tags/ClassStatusTag';
import { PageLayout } from '../../containers/PageLayout';
import { ClassGroupTrialTag } from '../../modules/class-groups/components/tags/ClassGroupTrialTag';

export const ReportsPage = () => {
  const renderItem = (record: any) => {
    return (
      <List.Item>
        <List.Item.Meta
          title={
            <span>
              {`Викладач (${formatFullName(record.teacher)}) отримав: ${
                record.teacherPayAmount
              } UAH, група (${record.classGroup.name}) заплатила ${sumBy(
                record.studentDetails,
                'payment'
              )} UAH`}{' '}
              <ClassGroupTrialTag isTrial={record.classGroup.isTrial} />
              <ClassStatusTag status={record.status} />
            </span>
          }
          description={`${record.date}: ${record.time} - ${addDurationToTime(
            record.time,
            record.classGroup.duration
          )}`}
        />
      </List.Item>
    );
  };

  const renderTotal = (response: any, loading: boolean) => {
    const teacherPayAmountSum = response?.teacherPayAmountSum ?? 0;
    const studentPaymentsSum = response?.studentPaymentsSum ?? 0;
    const total = studentPaymentsSum - teacherPayAmountSum;

    return (
      <Spinner
        spinning={loading}
        tip={null}
        wrapperClassName="reports-page__total-wrapper"
      >
        <Typography.Text style={{ fontWeight: 'bold', fontSize: 20 }}>
          {tr('reports-page.teachers-total', {
            teacherPayAmountSum: response?.teacherPayAmountSum ?? '',
          })}
        </Typography.Text>
        <Typography.Text style={{ fontWeight: 'bold', fontSize: 20 }}>
          {tr('reports-page.students-total', {
            studentPaymentsSum: response?.studentPaymentsSum ?? '',
          })}
        </Typography.Text>
        <Typography.Text style={{ fontWeight: 'bold', fontSize: 20 }}>
          {tr('reports-page.total', {
            total,
          })}
        </Typography.Text>
      </Spinner>
    );
  };

  return (
    <PageLayout title="Звіти">
      <ReportList renderItem={renderItem} renderTotal={renderTotal} />
    </PageLayout>
  );
};
