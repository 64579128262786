import { classGroupsColumns, ClassGroupType } from '../../../namespace';
import { useQuery } from '@apollo/client';
import { GET_CLASS_GROUPS_QUERY } from '../../../graphql';
import { TableProps } from '../../../../../components/Table';
import React, { FC, useState } from 'react';
import { TableWithFilters } from '../../../../../components/TableWithFilters';
import { StudentsSelector } from '../../../../users/components/selectors/StudentsSelector';
import { TeachersSelector } from '../../../../users/components/selectors/TeachersSelector';
import { ClassGroupTypeSelector } from '../../selectors/ClassGroupTypeSelector';
import { Checkbox } from 'antd';

export type ClassGroupTableProps = Omit<
  TableProps,
  'columns' | 'dataSource'
> & {
  onRowClick?: (record: any) => void;
  allowedFilters?: string[];
  renderExtraButtons?: () => React.ReactNode;
};

export const ClassGroupTable: FC<ClassGroupTableProps> = ({
  onRowClick,
  allowedFilters,
  renderExtraButtons,
  ...props
}) => {
  const [studentsIds, setStudentsIds] = useState<number[]>([]);
  const [teachersIds, setTeachersIds] = useState<number[]>([]);
  const [classGroupTypes, setClassGroupTypes] = useState<ClassGroupType[]>([]);
  const [hideScheduledTrialClasses, setHidePasseTrialClasses] =
    useState<boolean>(true);

  const { data, loading } = useQuery(GET_CLASS_GROUPS_QUERY, {
    variables: {
      studentIdIn: studentsIds,
      teacherIdIn: teachersIds,
      typeIn: classGroupTypes,
      trialClassScheduled: hideScheduledTrialClasses ? false : undefined,
    },
  });

  return (
    <>
      <TableWithFilters
        filters={[
          {
            name: 'students',
            component: (
              <StudentsSelector
                mode="multiple"
                onChange={(studentsIds) => setStudentsIds(studentsIds)}
                value={studentsIds}
                style={{ minWidth: '300px', marginLeft: '10px' }}
                maxTagCount="responsive"
              />
            ),
          },
          {
            name: 'teachers',
            component: (
              <TeachersSelector
                mode="multiple"
                onChange={(teachersIds) => setTeachersIds(teachersIds)}
                value={teachersIds}
                style={{ minWidth: '300px', marginLeft: '10px' }}
                maxTagCount="responsive"
                showRoles={true}
              />
            ),
          },
          {
            name: 'classGroupTypes',
            component: (
              <ClassGroupTypeSelector
                mode="multiple"
                onChange={(classGroupTypes) =>
                  setClassGroupTypes(classGroupTypes)
                }
                value={classGroupTypes}
                style={{ minWidth: '200px', marginLeft: '10px' }}
                maxTagCount="responsive"
              />
            ),
          },
          {
            name: 'isTrial',
            component: (
              <Checkbox
                onChange={(event) =>
                  setHidePasseTrialClasses(event.target.checked)
                }
                checked={hideScheduledTrialClasses}
                style={{ minWidth: '200px', marginLeft: '10px' }}
              >
                Hide scheduled trial classes
              </Checkbox>
            ),
          },
        ]}
        allowedFilters={allowedFilters}
        renderExtraButtons={renderExtraButtons}
        columns={classGroupsColumns}
        loading={loading}
        dataSource={data?.getClassGroups}
        {...props}
        onRow={(record) => {
          return {
            onClick: () => {
              if (onRowClick) {
                onRowClick(record);
              }
            },
          };
        }}
      />
    </>
  );
};
