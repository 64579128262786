const config: any = {
  modal: {
    buttons: {
      cancel: 'Скасувати',
      confirm: 'Підтвердити',
    },
  },
  calendar: {
    'menu-title': 'Календар',
    classes: {
      add: {
        label: 'Додати урок',
        title: 'Додавання уроку',
        fields: {
          student: {
            label: 'Учень',
            errors: {
              required: 'Виберіть учня',
            },
          },
          duration: {
            label: 'Тривалість',
            errors: {
              required: 'Виберіть тривалість',
            },
            options: {
              oneHour: '1 година',
              oneHourAndHalf: '1,5 години',
              twoHours: '2 години',
            },
          },
          time: {
            label: 'Час',
            errors: {
              required: 'Виберіть час',
            },
          },
        },
      },
      move: {
        title: 'Перенести урок',
        label: 'Перенести',
        fields: {
          date: {
            label: 'Дата',
            errors: {
              required: 'Виберіть дату',
            },
          },
          time: {
            label: 'Час',
            errors: {
              required: 'Виберіть час',
            },
          },
        },
        actions: {
          move: {
            label: 'Перенести',
          },
        },
      },
      list: {
        item: {
          description: 'Час: {time}',
        },
      },
    },
  },
  students: {
    'menu-title': 'Список студентів',
  },
  'my-classes': {
    'menu-title': 'Мої заняття',
    classes: {
      table: {
        actions: {
          updateStatus: {
            label: 'Апдейтнути статус',
            title: 'Апдейтнути статус',
            fields: {
              topic: {
                label: 'Тема',
                errors: {
                  required: 'Введіть тему',
                },
              },
              description: {
                label: 'Опис',
                errors: {
                  required: 'Введіть опис',
                },
              },
              homework: {
                label: 'Домашнє завдання',
                errors: {
                  required: 'Введіть домашнє завдання',
                },
              },
              'actual-duration': {
                label: 'Фактична тривалість (у хвилинах)',
                errors: {
                  required: 'Введіть фактичну тривалість (у хвилинах)',
                },
              },
            },
          },
          'report-cancellation': {
            label: 'Повідомити про скасування',
            title: 'Повідомити про скасування',
            fields: {
              cancellationType: {
                label: 'Тип скасування',
                errors: {
                  required: 'Виберіть хто скасував урок',
                },
              },
              'cancellation-notified-at': {
                label: 'Коли були повідомлені обидві сторони',
                errors: {
                  required: 'Виберіть коли були повідомлені обидві сторони',
                },
              },
              'canceled-reason': {
                label: 'Причина скасування',
                errors: {
                  required: 'Введіть причину скасування',
                },
              },
            },
          },
        },
      },
      actions: {
        addClass: {
          label: 'Додати урок',
        },
      },
    },
  },
  'students-page': {
    'menu-title': 'Список студентів',
    table: {
      columns: {
        name: {
          label: "Ім'я",
        },
        email: {
          label: 'E-mail',
        },
        notes: {
          label: 'Нотатки',
        },
      },
    },
  },
  'report-page': {
    'menu-title': 'Звіт',
    total: 'Сума {amount} грн',
  },
  'reports-page': {
    'teachers-total': 'Виплата викладачам {teacherPayAmountSum} грн',
    'students-total': 'Оплачено студентами {studentPaymentsSum} грн',
    total: 'Загалом {total} грн',
  },
  classes: {
    table: {
      columns: {
        id: 'ID',
        status: 'Статус',
        'approval-status': 'Підтвердження',
        duration: 'Тривалість',
        date: 'Дата',
        time: 'Час',
        'teacher-pay-amount': 'Виплата вчителю',
        'students-payment': 'Оплата студентами',
        'teacher-name': 'Вчитель',
        'student-name': 'Студент',
        'actual-duration': 'Фактична тривалість (у хвилинах)',
        topic: 'Тема',
        description: 'Опис',
        homework: 'Домашнє завдання',
        'canceled-type': 'Тип скасування',
        'canceled-reason': 'Причина скасування',
        'cancellation-notified-at': 'Коли були повідомлені обидві сторони',
        actions: 'Дії',
      },
    },
    'add-class': {
      title: 'Додавання уроку',
      fields: {
        date: {
          label: 'Дата',
          errors: {
            required: 'Виберіть дату',
          },
        },
        status: {
          label: 'Статус',
        },
        student: {
          label: 'Учень',
          errors: {
            required: 'Виберіть учня',
          },
        },
        duration: {
          label: 'Тривалість',
          errors: {
            required: 'Виберіть тривалість',
          },
        },
        time: {
          label: 'Час',
          errors: {
            required: 'Виберіть час',
          },
        },
      },
      actions: {
        add: {
          label: 'Додати урок',
        },
        cancel: {
          label: 'Скасувати',
        },
      },
    },
    status: {
      confirmed: {
        label: 'Підтверджено',
      },
      scheduled: {
        label: 'Заплановано',
      },
      cancelled: {
        label: 'Скасовано',
      },
      'not-confirmed': {
        label: 'Не підтверджено',
      },
    },
    'approval-status': {
      approved: {
        label: 'Підтверджено',
      },
      rejected: {
        label: 'Відхилено',
      },
      pending: {
        label: 'Очікує підтвердження',
      },
    },
    duration: {
      'one-hour': {
        label: '1 година',
      },
      'one-and-half-hour': {
        label: '1,5 години',
      },
      'two-hours': {
        label: '2 години',
      },
    },
    'canceled-type': {
      'teacher-canceled': {
        label: 'Вчитель скасував',
      },
      'student-canceled': {
        label: 'Студент скасував',
      },
    },
  },
  common: {
    loading: 'Завантаження...',
    'time-picker': {
      placeholder: 'Виберіть час',
    },
    'date-picker': {
      placeholder: 'Виберіть дату',
    },
    'users-selector': {
      placeholder: 'Виберіть користувача',
      students: {
        placeholder: 'Виберіть учня',
      },
      teachers: {
        placeholder: 'Виберіть вчителя',
      },
    },
    'class-status-selector': {
      placeholder: 'Виберіть статус',
    },
    'class-duration-selector': {
      placeholder: 'Виберіть тривалість',
    },
    'date-presets': {
      today: {
        label: 'Сьогодні',
      },
      yesterday: {
        label: 'Вчора',
      },
      'last-7-days': {
        label: 'Останні 7 днів',
      },
      'last-14-days': {
        label: 'Останні 14 днів',
      },
      'last-30-days': {
        label: 'Останні 30 днів',
      },
    },
  },
};

export const tr = (path: string, args?: Record<string, unknown>) => {
  let final = path.split('.').reduce((acc, key) => {
    return acc[key] || path;
  }, config);

  if (typeof final === 'object') {
    throw new Error('Invalid path in tr, as it points to an object');
  }

  for (const argsKey in args) {
    final = final.replace('{' + argsKey + '}', args[argsKey] as string);
  }

  return final;
};
