export function transformClassGroupToForm(rawData?: any) {
  if (!rawData) {
    return null;
  }

  return {
    ...rawData,
    teacherId: rawData.teacher.id,
    studentsIds: rawData.studentDetails.map((item: any) => item.student.id),
    students: rawData.studentDetails.map((item: any) => ({
      studentId: item.student.id,
      rate: item.rate,
    })),
  };
}
