import React from 'react';
import { Tag, TagProps } from 'antd';

interface ClassGroupTrialTagProps extends TagProps {
  isTrial: boolean;
  trialScheduled?: boolean;
}

export const ClassGroupTrialTag: React.FC<ClassGroupTrialTagProps> = ({
  isTrial,
  trialScheduled,
  ...props
}) => {
  if (!isTrial) {
    return null;
  }

  return (
    <Tag color="yellow-inverse" {...props}>
      Trial
      {trialScheduled && ' (scheduled)'}
    </Tag>
  );
};
