import React from 'react';
import { UserRole } from '../../../../../lib/types';
import { UsersSelector, UsersSelectorProps } from '../UserRoleSelector';
import { tr } from '../../../../../lib/translate';

export interface TeachersSelectorProps
  extends Omit<UsersSelectorProps, 'roleIn'> {
  showAdmins?: boolean;
}

export const TeachersSelector: React.FC<TeachersSelectorProps> = ({
  showAdmins,
  ...props
}) => {
  return (
    <UsersSelector
      roleIn={
        [UserRole.Teacher, showAdmins === false ? null : UserRole.Admin].filter(
          Boolean
        ) as UserRole[]
      }
      placeholder={tr('common.users-selector.teachers.placeholder')}
      showAddUserRole={UserRole.Teacher}
      {...props}
    />
  );
};
