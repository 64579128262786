import React from 'react';
import { UserModal } from '../UserModal';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_USER_QUERY,
  GET_USERS_QUERY_NAME,
  UPDATE_USER_MUTATION,
} from '../../../graphql';

export interface EditUserModalProps {
  userId: string | null;
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const EditUserModal: React.FC<EditUserModalProps> = ({
  userId,
  open,
  setOpen,
}) => {
  const { data, loading: userLoading } = useQuery(GET_USER_QUERY, {
    variables: {
      id: userId,
    },
    fetchPolicy: 'network-only',
  });
  const [updateUserMutate, { loading }] = useMutation(UPDATE_USER_MUTATION, {
    refetchQueries: [GET_USERS_QUERY_NAME],
    awaitRefetchQueries: true,
  });

  const onSubmit = async (values: any) => {
    await updateUserMutate({
      variables: {
        id: userId,
        ...values,
      },
    });

    setOpen(false);
  };

  if (!userId) {
    return null;
  }

  return (
    <UserModal
      open={open}
      setOpen={setOpen}
      onSubmit={onSubmit}
      loading={userLoading || loading}
      values={{
        ...data?.getUser,
        assignedStudents: data?.getUser?.assignedStudents?.map(
          (student: any) => student.id
        ),
      }}
      partial={true}
      title="Edit user"
      okText="Edit"
    />
  );
};
