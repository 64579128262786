import './LoadingPage.css';
import { FC, ReactNode } from 'react';

export interface LoadingPageProps {
  loading: boolean;
  children?: ReactNode;
}

export const LoadingPage: FC<LoadingPageProps> = ({ loading, children }) => {
  return (
    <div className="loading-page" aria-loading={String(loading)}>
      <div className="loading-page-foreground" aria-loading={String(loading)} />
      {/*<img className="loading-page-spitz" src={SpitzImage} aria-loading={String(loading)}/>*/}
      {loading ? null : children}
    </div>
  );
};
