import React from 'react';
import { ClassGroupBaseModal } from '../ClassGroupBaseModal';
import { useMutation, useQuery } from '@apollo/client';
import {
  EDIT_CLASS_GROUP_MUTATION,
  GET_CLASS_GROUP_QUERY,
} from '../../../graphql';
import { transformClassGroupToForm } from '../../../utils';

export interface EditClassGroupModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  classGroupId: number | null;
}

export const EditClassGroupModal: React.FC<EditClassGroupModalProps> = ({
  open,
  setOpen,
  classGroupId,
}) => {
  const { data, loading } = useQuery(GET_CLASS_GROUP_QUERY, {
    variables: {
      id: classGroupId,
    },
    skip: !classGroupId,
  });
  const [mutate, { loading: editLoading }] = useMutation(
    EDIT_CLASS_GROUP_MUTATION
  );

  const onFinish = async (values: any) => {
    await mutate({
      variables: {
        id: classGroupId,
        ...values,
        studentDetails: values.students.map((item: any) => ({
          ...item,
        })),
      },
    });
    setOpen(false);
  };

  return (
    <ClassGroupBaseModal
      open={open}
      setOpen={setOpen}
      onFinish={onFinish}
      title="Edit group"
      okText="Edit"
      initialValues={transformClassGroupToForm(data?.getClassGroup)}
      loading={loading}
      confirmLoading={editLoading}
      successMessage={
        data?.getClassGroup
          ? `Group (${data.getClassGroup.id}) edited successfully`
          : undefined
      }
    />
  );
};
