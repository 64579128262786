import { gql } from '@apollo/client';
import { get } from 'lodash';

export function makeGqlRequest(request: string) {
  const gqlRequest = gql(request);

  return {
    request: gqlRequest,
    name: get(gqlRequest, 'definitions.0.name.value') as unknown as string,
  };
}
