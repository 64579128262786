import React, { useEffect } from 'react';
import { useForm } from 'antd/es/form/Form';
import { Button, Checkbox, Form, Input, InputNumber, Modal } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { ClassApprovalStatus, ClassStatus } from '../../../../../lib/types';
import { tr } from '../../../../../lib/translate';
import { client } from '../../../../../client';
import {
  durationToMinutes,
  formatFullName,
  minutesToTime,
} from '../../../../../lib/utils';
import {
  GET_CLASSES_QUERY_NAME,
  GET_REQUIRED_ATTENTION_CLASSES_COUNT_QUERY_NAME,
  UPDATE_CLASS_MUTATION,
} from '../../../graphql';
import { GET_CLASSES_REPORT_QUERY_NAME } from '../../../../reports/graphql';

export interface UpdateClassStatusModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  classId: string | null;
}

export const UpdateClassStatusModal: React.FC<UpdateClassStatusModalProps> = ({
  open,
  setOpen,
  classId,
}) => {
  const handleCancel = () => {
    setOpen(false);
  };
  const [updateClass, { loading }] = useMutation(UPDATE_CLASS_MUTATION, {
    refetchQueries: [
      GET_CLASSES_QUERY_NAME,
      GET_CLASSES_REPORT_QUERY_NAME,
      GET_REQUIRED_ATTENTION_CLASSES_COUNT_QUERY_NAME,
    ],
    awaitRefetchQueries: true,
  });

  const [form] = useForm();

  const classItem = client.readFragment({
    id: `ClassModel:${classId}`,
    fragment: gql`
      fragment ClassModel on ClassModel {
        id
        date
        classGroup {
          id
          duration
        }
        studentDetails {
          student {
            id
            firstName
            lastName
          }
        }
      }
    `,
  });

  useEffect(() => {
    if (!classItem) {
      return;
    }
    form.setFieldValue(
      'actualDuration',
      durationToMinutes(classItem.classGroup.duration)
    );
  }, [classItem?.id]);

  const onFinish = async (values: any) => {
    await updateClass({
      variables: {
        id: classId,
        topic: values.topic,
        description: values.description,
        homework: values.homework,
        status: ClassStatus.Confirmed,
        approvalStatus: ClassApprovalStatus.Approved,
        actualDuration: minutesToTime(values.actualDuration),
        studentsAttendance: values.studentsAttendance,
      },
    });

    setOpen(false);
  };

  return (
    <Modal
      open={open}
      title={tr('my-classes.classes.table.actions.updateStatus.title')}
      onCancel={handleCancel}
      confirmLoading={loading}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {tr('modal.buttons.cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={form.submit}
          loading={loading}
        >
          {tr('modal.buttons.confirm')}
        </Button>,
      ]}
    >
      <Form
        name="basic"
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item
          label={tr(
            'my-classes.classes.table.actions.updateStatus.fields.topic.label'
          )}
          name="topic"
          rules={[
            {
              message: tr(
                'my-classes.classes.table.actions.updateStatus.fields.topic.errors.required'
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={tr(
            'my-classes.classes.table.actions.updateStatus.fields.description.label'
          )}
          name="description"
          rules={[
            {
              message: tr(
                'my-classes.classes.table.actions.updateStatus.fields.description.errors.required'
              ),
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label={tr(
            'my-classes.classes.table.actions.updateStatus.fields.homework.label'
          )}
          name="homework"
          rules={[
            {
              message: tr(
                'my-classes.classes.table.actions.updateStatus.fields.homework.errors.required'
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={tr(
            'my-classes.classes.table.actions.updateStatus.fields.actual-duration.label'
          )}
          name="actualDuration"
          rules={[
            {
              required: true,
              message: tr(
                'my-classes.classes.table.actions.updateStatus.fields.actual-duration.errors.required'
              ),
            },
          ]}
        >
          <InputNumber min={0} max={120} />
        </Form.Item>
        <Form.Item
          name="studentsAttendance"
          label="Присутність"
          tooltip="Позначте студента який був присутній на занятті, якщо студент не був присутній не відмічайте його"
        >
          <Checkbox.Group>
            {classItem?.studentDetails?.map((detail: any) => {
              return (
                <Checkbox
                  value={detail.student.id}
                  style={{ lineHeight: '32px' }}
                >
                  {formatFullName(detail.student)}
                </Checkbox>
              );
            })}
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};
