import React, { useCallback, useEffect, useState } from 'react';
import './UsersTable.css';
import { Button, Input, notification, Popconfirm, Space, Tooltip } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import { RoleTag } from '../../tags/RoleTag';
import { UserRole } from '../../../../../lib/types';
import { EditUserModal } from '../../modals/EditUserModal';
import { formatFullName } from '../../../../../lib/utils';
import { Table } from '../../../../../components/Table';
import {
  ARCHIVE_USER_MUTATION,
  GET_USERS_QUERY,
  GET_USERS_QUERY_NAME,
} from '../../../graphql';
import { ACT_AS_MUTATION } from '../../../../auth/graphql';
import { setAccessToken } from '../../../../auth/utils';
import { useCurrentUser } from '../../../../../hooks/useCurrentUser';
import { GET_CLASS_GROUPS_QUERY } from '../../../../class-groups/graphql';
import { debounce } from 'lodash';

export const UsersTable = () => {
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);

  const { data, loading } = useQuery(GET_USERS_QUERY, {
    variables: {
      limit: 1000,
    },
  });
  const [actAsMutate, { loading: actAsLoading }] = useMutation(
    ACT_AS_MUTATION,
    {}
  );
  const [archiveMutate, { loading: archiveLoading }] = useMutation(
    ARCHIVE_USER_MUTATION,
    {
      refetchQueries: [GET_USERS_QUERY_NAME, GET_CLASS_GROUPS_QUERY],
      awaitRefetchQueries: true,
    }
  );
  const [searchValue, setSearchValue] = useState('');
  const user = useCurrentUser();

  const onActAs = async (id: number) => {
    const { data } = await actAsMutate({
      variables: {
        userId: id,
      },
    });

    setAccessToken(data.actAs.token);

    window.location.href = '/';
  };

  const onArchive = async (id: number) => {
    const {
      data: {
        archiveUser: {
          role,
          teacherClassesArchived,
          groupClassesArchived,
          coupleClassesArchived,
          individualClassesArchived,
          groupClassesKicked,
          coupleClassesKicked,
        },
      },
    } = await archiveMutate({
      variables: {
        id,
      },
    });

    if (role === UserRole.Student) {
      let content = '';
      if (groupClassesArchived) {
        content += `${groupClassesArchived} groups archived\n\n`;
      }

      if (coupleClassesArchived) {
        content += `${coupleClassesArchived} couple groups archived\n\n`;
      }

      if (individualClassesArchived) {
        content += `${individualClassesArchived} individual groups archived\n\n`;
      }

      if (groupClassesKicked) {
        content += `was kicked from ${groupClassesKicked} groups\n\n`;
      }

      if (coupleClassesKicked) {
        content += `was kicked from ${coupleClassesKicked} couple groups\n\n`;
      }

      notification.success({
        message: 'Student archived successfully',
        description: `Student archived successfully\n\n${content}`,
      });
    } else {
      let content = '';

      if (teacherClassesArchived) {
        content += `${teacherClassesArchived} classes archived\n\n`;
      }

      notification.success({
        message: 'Teacher archived successfully',
        description: 'Teacher archived successfully\n\n' + content,
      });
    }
  };

  const onSearchInputChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
    }, 200),
    []
  );

  useEffect(() => {
    if (!editUserModalOpen) {
      setUserId(null);
    }
  }, [editUserModalOpen]);

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      key: 'name',
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              setUserId(record.id);
              setEditUserModalOpen(true);
            }}
          >
            {formatFullName(record)}
          </a>
        </Space>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role: UserRole) => {
        return <RoleTag role={role} />;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <div className="users-table__actions">
            {record.role !== UserRole.Student && record.id !== user.id && (
              <Tooltip title="You're going to see the view as that person">
                <Button
                  onClick={() => onActAs(record.id)}
                  type="primary"
                  loading={actAsLoading}
                >
                  Act as
                </Button>
              </Tooltip>
            )}
            {record.id !== user.id && (
              <Popconfirm
                title="User archivation"
                description="Are you sure you want to archive this user?"
                onConfirm={() => onArchive(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{
                    backgroundColor: '#ff4d4f',
                    color: '#fff',
                  }}
                  danger
                  loading={archiveLoading}
                >
                  Archive
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="users-table">
      <div className="users-table__bar">
        <Input
          placeholder={'Type to search...'}
          style={{ width: '250px' }}
          onChange={onSearchInputChange}
        />
      </div>
      <Table
        columns={columns}
        dataSource={data?.getUsers
          ?.filter((user: any) => {
            return (
              user.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
              formatFullName(user)
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            );
          })
          .map((user: any) => {
            return {
              ...user,
              key: user.id,
              children: user.assignedStudents?.length
                ? user.assignedStudents
                : undefined,
            };
          })}
        pagination={false}
        loading={loading}
      />
      <EditUserModal
        userId={userId}
        open={editUserModalOpen}
        setOpen={setEditUserModalOpen}
      />
    </div>
  );
};
